import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner, Input, CustomInput, Button, Label } from "reactstrap";
import Loading from "../components/Loading";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import "../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

export const StatusUpdates = () => {

  const dateNow = new Date();
  const now = new Date(dateNow).toISOString();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [fromDate, setFromDate] = useState(yesterday);
  const [toDate, setToDate] = useState(now);
  const [all, setAll] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    //console.log(dateNow);
    //console.log(now);
    const getStatusUpdates = async () => {
      try {
        setAccessToken(await getAccessTokenSilently());

        const response = await fetch(`${apiOrigin}/api/statusUpdates?from=${fromDate}&to=${toDate}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        });

        const responseData = await response.json();
        //console.log(responseData);
        setStatusUpdates(responseData);
        setLoading(false);
      } catch (error) {
        alert("error: " + error);
      }
    };

    getStatusUpdates();
  }, []);

  const refreshStatusUpdates = async () => {
    setLoading(true);

    const queryParams = all ? "all=true" : `from=${fromDate}&to=${toDate}`;

    try {
      const response = await fetch(`${apiOrigin}/api/statusUpdates` + (`?${queryParams}` ?? ""), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = await response.json();
      //console.log(responseData);
      setStatusUpdates(responseData);
      setLoading(false);
    } catch (error) {
      alert("error: " + error);
    }
  };

  const columns = [
    {
      dataField: "rowKey",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "patientName",
      text: "Patient Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "therapistName",
      text: "Therapist Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "discipline",
      text: "Discipline",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "statusChange",
      text: "Status Change",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      formatter: (cell, row) => {
        const cellSplit = cell.split("=>");
        return <div><span>{cellSplit[0]}</span><span> &#8594; </span><span>{cellSplit[1]}</span></div>
      }
    },
    {
      dataField: "timestamp",
      text: "Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      formatter: (cell, row) => <span>{(new Date(cell)).toLocaleString().split(',')[0]}</span>,
    }
  ];

  const subFourHours = (date) => {
    return new Date(new Date(date).setHours(new Date(date).getHours() - 4)).toISOString().split('.')[0]
  }

  return (
    <>
      <div style={{ textAlign: "center", color: "#238dcf" }}><h1>Status Updates</h1></div>
      <hr />
      <div className="status-filter" style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
        <Label style={{ marginRight: "5px" }} for="from">From:</Label>
        <Input type="datetime-local" id="from" name="from" value={subFourHours(fromDate)} onChange={(e) => setFromDate(e.target.value)} />
        <Label style={{ marginLeft: "20px", marginRight: "5px" }} for="to">To:</Label>
        <Input type="datetime-local" id="to" name="to" value={subFourHours(toDate)} onChange={(e) => setToDate(e.target.value)} />
        <CustomInput type="checkbox" className="checkbox-ml" id="all" name="all" label="All" checked={all} onChange={() => setAll(!all)} />{" "}
        <Button color="primary" style={{ marginLeft: "20px" }} onClick={refreshStatusUpdates}>
          Refresh
        </Button>
      </div>
      <BootstrapTable
        keyField="rowKey"
        data={statusUpdates}
        columns={columns}
        striped
        hover
        condensed
        pagination={paginationFactory()}
      />
      {loading && <Spinner animation="border" role="status" />}
    </>
  );
};

export default withAuthenticationRequired(StatusUpdates, {
  onRedirecting: () => <Loading />,
});
