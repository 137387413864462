import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import TagBox from "devextreme-react/tag-box";
import "devextreme-react/text-area";
import { useLocation } from "react-router-dom";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DISCIPLINE_STATUSES, DISCIPLINE_TYPES } from "../../constants";

export const PatientFilter = ({
  setFilter,
  filter,
  flexDirection,
  isPopup,
}) => {
  const dateFilterOptions = ["All", "Today", "Tomorrow", "Past Due", "Custom"];

  const fieldStyle = {
    marginTop: isPopup ? "20px" : "0px",
    marginLeft: isPopup ? "0px" : "7px",
    width: isPopup ? "100%" : "auto",
  };

  return (
    <div
      className="editors-container"
      style={{
        display: "flex",
        marginTop: "4px",
        flexDirection: flexDirection,
      }}
    >
      <div style={fieldStyle}>
        <SelectBox
          height="35px"
          width={isPopup ? "100%" : "130px"}
          defaultValue="All"
          items={dateFilterOptions}
          placeholder="Follow Up Filter"
          value={filter?.followUpType}
          onValueChanged={(e) =>
            setFilter({ ...filter, followUpType: e.value })
          }
        />
      </div>

      {filter?.followUpType == "Custom" && (
        <>
          <div style={fieldStyle}>
            <DateBox
              height="35px"
              width={isPopup ? "100%" : "150px"}
              label="From Date"
              defaultValue={new Date()}
              value={filter?.followUpFrom}
              onValueChanged={(e) =>
                setFilter({ ...filter, followUpFrom: e.value })
              }
            />
          </div>
          <div style={fieldStyle}>
            <DateBox
              height="35px"
              width={isPopup ? "100%" : "150px"}
              label="To Date"
              defaultValue={new Date().setDate(new Date().getDate() + 7)}
              value={filter?.followUpTo}
              onValueChanged={(e) =>
                setFilter({ ...filter, followUpTo: e.value })
              }
            />
          </div>
        </>
      )}
      <div style={fieldStyle}>
        <TagBox
          height="35px"
          width={isPopup ? "100%" : "250px"}
          showClearButton
          defaultValue="PENDING"
          items={DISCIPLINE_STATUSES}
          placeholder="Discipline Status"
          value={filter?.disciplineStatus}
          onValueChanged={(e) =>
            setFilter({ ...filter, disciplineStatus: e.value })
          }
        />
      </div>

      <div style={fieldStyle}>
        <SelectBox
          height="35px"
          width={isPopup ? "100%" : "150px"}
          showClearButton
          defaultValue="All"
          items={DISCIPLINE_TYPES}
          placeholder="Discipline Type"
          value={filter?.disciplineType}
          onValueChanged={(e) =>
            setFilter({ ...filter, disciplineType: e.value })
          }
        />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(PatientFilter, {
  //onRedirecting: () => <Loading />,
});
