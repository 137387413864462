import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Button, Divider, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;
const serviceUrl = `${apiOrigin}/api`;

export default function DisciplineSimpleGrid({ patientsId, editDiscipline }) {
    const { getAccessTokenSilently } = useAuth0();
    const [disciplines, setDisciplines] = useState();


    useEffect(() => {
        const getToken = async () => {
            const token = await getAccessTokenSilently();
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            let { data } = await axios.get(`${serviceUrl}/PatientDisciplines?patientId=${patientsId}`, config);
            setDisciplines(data.data);
        };
        getToken();
    }, [patientsId]);

    function parseDate(addedOn) {
        let fmt = new Intl.DateTimeFormat("en-US", {
            dateStyle: "short",
            timeStyle: "short",
            timeZone: "est",
            hour12: true,
        });
        if (!addedOn) {
            return '';
        }
        let parsedDate = fmt.format((new Date(addedOn)));
        parsedDate = parsedDate.replace(/ (..)$| at\b/g, "$1");
        return parsedDate;
    }

    function handleDoubleClick(e) {
        editDiscipline({ ...(e), patientId: patientsId });
    }

    return (
        <TableContainer sx={{ maxHeight: '100%' }} mb={1}>
            <Table aria-label="simple table" size="small"
                sx={{ maxHeight: 440, [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Discipline</TableCell>
                        <TableCell>Therapist</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {disciplines && disciplines.map((row) => (
                        <>
                            <TableRow key={row.disciplinesAndStatusesId}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                                style={{ borderBottom: 0 }}>
                                <TableCell component="th" scope="row">
                                    <Link underline="always" href="#" size="small" onClick={(e) => {
                                        e.preventDefault();
                                        handleDoubleClick(row);
                                    }}>
                                        {row.status ? row.status : "N/A"}
                                    </Link>
                                </TableCell>
                                <TableCell >{row.type}</TableCell>
                                <TableCell >{row.therapist}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Typography variant="caption" gutterBottom component="div">
                                        {row.addedBy && `Added: ${row.addedBy} | ${parseDate(row.addedOn)}`}
                                        {row.addedBy && <br/>}
                                        {row.modifiedBy && `Modified ${row.modifiedBy} | ${parseDate(row.modifiedOn)}`}
                                    </Typography>
                                    <Divider />
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};