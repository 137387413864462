import React, { useEffect } from "react";
import { Input } from 'reactstrap';

/* global google */

const AddressAutocomplete = ({ setAddress, value }) => {

    var autocomplete = null;
    const autocompleteInput = React.createRef();

    useEffect(() => {
        autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current,
            { "types": ["geocode"] });

        autocomplete.addListener('place_changed', handlePlaceChanged);
    }, []);

    const handlePlaceChanged = () => {
        const place = autocomplete.getPlace();
        // console.log(place);
        const fullAddress = place.formatted_address.replace(", USA", "");
        const address1 = place.address_components.filter(x => x.types.includes("street_number"))[0]?.short_name + " " + place.address_components.filter(x => x.types.includes("route"))[0]?.short_name;

        const city = [...place.address_components.filter(x => x.types.includes("sublocality_level_1") && x.types.includes("sublocality")),
                      ...place.address_components.filter(x => x.types.includes("political") && x.types.includes("neighborhood")),
                      ...place.address_components.filter(x => x.types.includes("political") && x.types.includes("locality")),
                      ...place.address_components.filter(x => x.types.includes("administrative_area_level_3") && x.types.includes("political"))
                    ][0]?.short_name;
        
        const state = place.address_components.filter(x => x.types.includes("administrative_area_level_1"))[0]?.short_name;
        
        const zip = place.address_components.filter(x => x.types.includes("postal_code"))[0]?.short_name;
        // console.log("all", [fullAddress, address1, city, state, zip]);
        if (setAddress)
            setAddress(fullAddress, address1, city, state, zip);
    }


    return (
        <Input
            type="text"
            id="autocomplete"
            placeholder="Enter address"
            innerRef={autocompleteInput}
            autoComplete="off"
            defaultValue={value}
        />
    );
}

export default AddressAutocomplete;
