import React, { useState, useEffect, useRef } from "react";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    RemoteOperations,
    HeaderFilter,
    ColumnChooser,
    Sorting,
    ColumnFixing,
    FilterPanel,
    FilterBuilderPopup,
    Export,
} from "devextreme-react/data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Box } from "@mui/system";
import ReactJson from 'react-json-view'

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;
const serviceUrl = `${apiOrigin}/api`;

export default function LogEventsView(props) {
    const [remoteDataSource, setRemoteDataSource] = useState();
    const { getAccessTokenSilently } = useAuth0();
    const [selectedEvent, setSelectedEvent] = useState();

    useEffect(() => {
        const getPatients = async () => {
            const token = await getAccessTokenSilently();
            setRemoteDataSource(createStore({
                key: "id",
                loadUrl: serviceUrl + "/LogEvents",
                onBeforeSend: (e, s) => {
                    s.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                },
            }));
        };
        getPatients();
    }, []);

    const onFocusedRowChanged = (e) => {
        const dataRow = e.row && e.row.data;
        setSelectedEvent(dataRow);
    };

    if (!remoteDataSource) {
        return <div>loading</div>
    }

    return (
        <Box height='99%' display='flex'>
            <Box m={1} width='75%' height='100%'>
                <DataGrid
                    remoteOperations
                    dataSource={remoteDataSource}
                    height="100%"
                    width="100%"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={false}
                    showBorders={true}
                    columnResizingMode={"widget"}
                    showColumnLines={false}
                    rowAlternationEnabled
                    focusedRowEnabled
                    autoNavigateToFocusedRow
                    onFocusedRowChanged={onFocusedRowChanged}
                >
                    <RemoteOperations groupPaging={true} />
                    <ColumnFixing enabled={true} />
                    <SearchPanel visible width={250} />
                    <HeaderFilter visible />
                    <ColumnChooser enabled />
                    <Sorting mode="multiple" />
                    <ColumnFixing />
                    <Paging defaultPageSize={40} />
                    <Pager showPageSizeSelector />
                    <FilterPanel visible />
                    <FilterBuilderPopup />
                    <Export enabled={true} />

                    <Column dataField="id" />
                    <Column dataField="level" />
                    <Column dataField="timeStamp" dataType="date" sortOrder="desc" format="shortDateShortTime" />
                    <Column dataField="message" />
                    <Column dataField="logEvent" />
                    <Column dataField="messageTemplate" />
                    <Column dataField="exception" />
                    <Column dataField="properties" />
                </DataGrid>
            </Box>
            <Box width='35%' height='100%' overflow='scroll'>
                <div>message</div>
                <ReactJson src={selectedEvent && JSON.parse(selectedEvent?.logEvent)} />
            </Box>
        </Box>
    );
};