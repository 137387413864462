import React, { useState, useEffect, useRef } from "react";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    RemoteOperations,
    HeaderFilter,
    ColumnChooser,
    Sorting,
    ColumnFixing,
    FilterPanel,
    FilterBuilderPopup,
    Export,
    Lookup,
    Popup,
    Editing
} from "devextreme-react/data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import { Form, Item } from "devextreme-react/form";
import DxAddressAutoComplete from '../../components/DxAddressAutocomplete';

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const serviceUrl = `${apiOrigin}/api`;
export default function FacilitiesDxGrid(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [remoteDataSource, setRemoteDataSource] = useState();
    const [directorsAndZipcodes, setDirectorsAndZipcodes] = useState([]);
    const [therapists, setTherapists] = useState([]);
    const gridRef = useRef();

    useEffect(() => {
        const getReferralSources = async () => {
            const token = await getAccessTokenSilently();
            setRemoteDataSource(createStore({
                key: "id",
                loadUrl: serviceUrl + "/Facilities",
                insertUrl: `${serviceUrl}/Facilities`,
                updateUrl: `${serviceUrl}/Facilities`,
                deleteUrl: `${serviceUrl}/Facilities`,
                onBeforeSend: (e, s) => {
                    s.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                },
            }));

            const config = { headers: { Authorization: `Bearer ${token}` } };
            const data = await axios.get(`${apiOrigin}/api/therapists`, config);
            const directors = data.data.filter(d => d.isDirector);
            setDirectorsAndZipcodes(directors);

            const therapists = await axios.get(`${apiOrigin}/api/therapistsList`, config);
            setTherapists(therapists.data);
        };
        getReferralSources();
    }, []);

    const setAutoCompleteAddress = (cell) => (fullAddress, address1, city, state, zip) => {
        // setPatient((prevState) => ({
        //     ...prevState,
        //     fullAddress: fullAddress,
        //     address1,
        //     city,
        //     state,
        //     zip,
        // }));
        cell.data.setValue(fullAddress);
        cell.data.row.cells[5].setValue(address1);
        cell.data.row.cells[6].setValue(city);
        cell.data.row.cells[7].setValue(state);
        cell.data.row.cells[8].setValue(zip);
        gridRef.current.instance.repaintRows(cell.data.rowIndex);
        //cell.setValue('city', 'test');
    }

    function avatarRender(cell, grid) {
        return (
            <DxAddressAutoComplete
                value={cell.data.value}
                setAddress={setAutoCompleteAddress(cell)}
            />
        );
    }

    function setStateValue(rowData, value) {
        rowData.fullAddress = value;
        this.defaultSetCellValue(rowData, value);
    }

    return (
        <>{
            remoteDataSource ?
                <DataGrid
                    ref={(ref) => { gridRef.current = ref; }}
                    remoteOperations
                    dataSource={remoteDataSource}
                    height="100%"
                    width="100%"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    columnResizingMode={"widget"}
                    showColumnLines={false}
                    rowAlternationEnabled
                    focusedRowEnabled
                    autoNavigateToFocusedRow
                >
                    <Editing
                        mode="row"
                        allowAdding={true}
                        allowDeleting={true}
                        allowUpdating={true}>
                        <Popup title="Facility Info" showTitle>
                            <Form>
                                <Item dataField="id" allowEditing={false} />
                                <Item dataField="directorId" />
                                <Item dataField="facilityName" />
                                <DxAddressAutoComplete />
                                <Item dataField="fullAddress" render={avatarRender} />
                                <Item dataField="address" />
                                <Item dataField="city" />
                                <Item dataField="state" />
                                <Item dataField="zip" />
                            </Form>
                        </Popup>
                    </Editing>
                    <RemoteOperations groupPaging={true} />
                    <ColumnFixing enabled={true} />
                    <SearchPanel visible width={250} />
                    <HeaderFilter visible />
                    <ColumnChooser enabled />
                    <Sorting mode="multiple" />
                    <ColumnFixing />
                    <Paging defaultPageSize={40} />
                    <Pager showPageSizeSelector />
                    <FilterPanel visible />
                    <FilterBuilderPopup />
                    <Export enabled={true} />

                    <Column dataField="id" allowEditing={false} />
                    <Column dataField="directorId">
                        <Lookup dataSource={directorsAndZipcodes} displayExpr="fullName" valueExpr="rowKey" />
                    </Column>
                    <Column dataField="leadTherapistId" caption="Lead Therapist">
                        <Lookup dataSource={therapists} displayExpr="fullName" valueExpr="rowKey" allowClearing/>
                    </Column>
                    <Column dataField="facilityName" />
                    <Column dataField="fullAddress" editCellComponent={avatarRender} />
                    <Column dataField="address" />
                    <Column dataField="city" />
                    <Column dataField="state" />
                    <Column dataField="zip" />                   
                </DataGrid >
                : <div></div>
        }</>
    );
};