import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useAxiosHelpers from '../../components/useAxiosHelpers';
import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

export default function RunPairingPage() {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const axiosHelper = useAxiosHelpers()

    const runPairing = async () => {
        try {
            setLoading(true);
            const token = await getAccessTokenSilently();
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            await axios.post(`${apiOrigin}/api/DailyPairingProcessHttp`, {}, config);
        } catch (ex) {
            axiosHelper.handleError(ex);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            height='calc(100vh - 90px)'
        >
            <Typography variant='h4' >Manually Run Pairing Sms</Typography>
            <Button
                disabled={loading}
                variant='contained'
                onClick={() => runPairing()}>Run Pairing Sms</Button>
            {loading && (
                <Box sx={{ width: '50%' }}>
                    <LinearProgress />
                </Box>
            )}
        </Stack>
    )
}