import React, { useState } from "react";
import { CustomInput, Input, Button } from "reactstrap";
import { DISCIPLINE_STATUSES, DISCIPLINE_TYPES } from "../constants";

const DisciplineTypesStatusSelect = ({
  fullUpdate,
  value,
  therapists,
  getFullName,
}) => {
  const [allDisciplines, setAllDisciplines] = useState(value);

  const typeChange = (type) => {
    //console.log(type);
    const newAllDisciplines =
      allDisciplines.filter((d) => d.Type === type).length === 1
        ? allDisciplines.filter((x) => x.Type !== type)
        : [
            ...allDisciplines,
            { Type: type, Status: "PENDING", Date: new Date().toISOString() },
          ];

    setAllDisciplines(newAllDisciplines);
  };

  const statusChange = (type, status) => {
    const discipline = allDisciplines.filter((d) => d.Type === type)[0];
    discipline.Status = status;
    discipline.Date = new Date().toISOString();

    setAllDisciplines([
      ...allDisciplines.filter((d) => d.Type !== type),
      discipline,
    ]);
  };

  const therapistIdChange = (type, therapistId) => {
    const discipline = allDisciplines.filter((d) => d.Type === type)[0];
    discipline.TherapistId = therapistId;
    discipline.Date = new Date().toISOString();

    setAllDisciplines([
      ...allDisciplines.filter((d) => d.Type !== type),
      discipline,
    ]);
  };

  const save = () => {
    fullUpdate(allDisciplines);
  };

  return (
    <div>
      {DISCIPLINE_TYPES.map((t, i) => {
        const currentDiscipline = allDisciplines.filter((x) => x.Type === t);
        //if (currentDiscipline.length === 1) console.log(getFullName(therapists.filter(x => x.rowKey === currentDiscipline[0].TherapistId)[0]))
        return (
          <div key={i}>
            <CustomInput
              key={i}
              type="checkbox"
              id={t + "checkbox"}
              label={t}
              name={t}
              onChange={() => typeChange(t)}
              checked={currentDiscipline.length === 1}
            />
            {currentDiscipline.length === 1 &&
              "Last Updated : " +
                new Date(currentDiscipline[0].Date)
                  .toLocaleString()
                  .split(",")[0]}
            {currentDiscipline.length === 1 && (
              <div style={{ display: "flex" }}>
                <Input
                  type="select"
                  name={t + "StatusSelet"}
                  id={t + "StatusSelet"}
                  defaultValue={
                    currentDiscipline.length === 1
                      ? currentDiscipline[0].Status
                      : ""
                  }
                  onChange={(e) => statusChange(t, e.target.value)}
                  disabled={currentDiscipline.length !== 1}
                  style={{ width: "200px" }}
                >
                  <option value="">Select Status</option>
                  {DISCIPLINE_STATUSES.map((x, i) => (
                    <option key={i} value={x}>
                      {x}
                    </option>
                  ))}
                </Input>
                <Input
                  type="select"
                  name={t + "TherapistSelet"}
                  id={t + "TherapistSelet"}
                  defaultValue={
                    currentDiscipline.length === 1
                      ? currentDiscipline[0].TherapistId
                      : ""
                  }
                  onChange={(e) => therapistIdChange(t, e.target.value)}
                  disabled={currentDiscipline.length !== 1}
                  style={{ width: "200px", marginLeft: "5px" }}
                >
                  <option value="">Select Therapist</option>
                  {therapists
                    .filter((x) => x.discipline && x.discipline.trim() == t)
                    .sort((a, b) =>
                      a.lastName > b.lastName
                        ? 1
                        : b.lastName > a.lastName
                        ? -1
                        : 0
                    )
                    .map((x, i) => (
                      <option key={i} value={x.rowKey}>
                        {getFullName(x)}
                      </option>
                    ))}
                </Input>
              </div>
            )}
            <hr />
          </div>
        );
      })}
      <Button onClick={save}>Save</Button>
    </div>
  );
};

export default DisciplineTypesStatusSelect;
