import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./history";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};

const theme = createTheme({
  palette: {
  }
});

const render = Component => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        scope="read:current_user"
      >
        <SnackbarProvider maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <React.Suspense fallback={<div>Loading....</div>}>
            <Component />
          </React.Suspense>
        </SnackbarProvider>
      </Auth0Provider>
    </ThemeProvider>,
    document.getElementById("root")
  );
}


render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}