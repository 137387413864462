import React, { useEffect, useRef, useState } from "react";
import { TextBox } from "devextreme-react/text-box";

/* global google */

const AddressAutocomplete = ({ setAddress, value}) => {
    const autocompleteInput = useRef();
    var autocomplete = useRef();
    const [fullAddress, setFullAddress] = useState();

    useEffect(() => {
        setTimeout(() => {
            if (!autocompleteInput.current) {
                return;
            }
            let input = autocompleteInput.current.instance.element().getElementsByTagName("input")[0];
            console.log(
                autocompleteInput.current.instance
                    .element()
                    .getElementsByTagName("input").length
            );
            autocomplete.current = new google.maps.places.Autocomplete(input, {
                types: ["geocode"],
            });

            autocomplete.current.addListener("place_changed", handlePlaceChanged);
        }, 400);
    }, [autocompleteInput.current]);

    const handlePlaceChanged = () => {
        const place = autocomplete.current.getPlace();
        // console.log(place);
        const fullAddress = place.formatted_address.replace(", USA", "");
        const address1 = place.address_components.filter(x => x.types.includes("street_number"))[0]?.short_name + " " + place.address_components.filter(x => x.types.includes("route"))[0]?.short_name;

        const city = [...place.address_components.filter(x => x.types.includes("sublocality_level_1") && x.types.includes("sublocality")),
        ...place.address_components.filter(x => x.types.includes("political") && x.types.includes("neighborhood")),
        ...place.address_components.filter(x => x.types.includes("political") && x.types.includes("locality")),
        ...place.address_components.filter(x => x.types.includes("administrative_area_level_3") && x.types.includes("political"))
        ][0]?.short_name;

        const state = place.address_components.filter(x => x.types.includes("administrative_area_level_1"))[0]?.short_name;

        const zip = place.address_components.filter(x => x.types.includes("postal_code"))[0]?.short_name;
        // console.log("all", [fullAddress, address1, city, state, zip]);
        setFullAddress(fullAddress);
        if (setAddress)
            setAddress(fullAddress, address1, city, state, zip);
    }


    return (
        <TextBox ref={autocompleteInput} value={fullAddress || value}
            type="text"
            id="autocomplete"
            placeholder="Enter address"
            autoComplete="off"
        ></TextBox>
    );
}

export default AddressAutocomplete;
