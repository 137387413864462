import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import "devextreme-react/text-area";
import { Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import useAxiosHelpers from "../../components/useAxiosHelpers";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

export default function DirectorEdit(props) {
    const [director, setDirector] = useState();
    const [therapist, setTherapist] = useState();
    const [value, setValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const parsed = queryString.parse(location.search);
    const directorId = parsed.directorId;
    const { getAccessTokenSilently } = useAuth0();
    const axiosHelpers = useAxiosHelpers();

    useEffect(() => {
        const getDirector = async () => {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            const config = { headers: { Authorization: `Bearer ${token}` } };
            try {
                let data = await axios.get(`${apiOrigin}/api/directorById/${directorId ?? 0}`, config);
                setDirector(data.data.directorAndZipCodes ?? {});
                if (data.data.directorAndZipCodes?.states) {
                    setValue(data.data.directorAndZipCodes.states.split('|'));
                }
                setTherapist(data.data.therapist);
                setIsLoading(false);
            } catch (ex) {
                axiosHelpers.handleError(ex);
                console.log(ex);
            } finally {
                setIsLoading(false);
            }
        };
        getDirector();
    }, []);

    const saveDirector = async () => {
        const token = await getAccessTokenSilently();
        try {
            setIsLoading(true);
            const config = { headers: { Authorization: `Bearer ${token}` } };
            if (value) {
                director.states = value.join("|");
            }
            let data = await axios.post(`${apiOrigin}/api/SaveDirector`, director, config);
            history.push('/directors');
        } catch (ex) {
            axiosHelpers.handleError(ex);
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    const onInputValueChanged = (e) => {
        setDirector((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    if (!director || !therapist) {
        return <div>Loading</div>
    }

    return (
        <>
            <Box m={3}>
                <Stack spacing={2}>
                    <Typography>Therapist Id: {director?.rowKey}</Typography>
                    {!director.id &&
                        <Autocomplete
                            value={director.rowKey}
                            onChange={(event, newValue) => {
                                setDirector((prevState) => ({
                                    ...prevState,
                                    rowKey: newValue?.rowKey
                                }));
                            }}
                            label="Therapist"
                            autoHighlight
                            options={therapist}
                            getOptionLabel={(therapist) => therapist.fullName}
                            renderInput={(params) => (
                                <TextField {...params} label="Therapist" placeholder="Therapist" />
                            )}
                        />}
                    {director.id &&
                        <>
                            <TextField value={director.firstName}
                                label="First Name"
                                name="firstName"
                                onChange={onInputValueChanged} />
                            <TextField value={director.lastName}
                                label="Last Name"
                                name="lastName"
                                onChange={onInputValueChanged} />
                        </>
                    }
                    <TextField value={director.zipCodes}
                        label="Zip Codes" multiline
                        name="zipCodes"
                        onChange={onInputValueChanged} />
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        multiple label="States"
                        autoHighlight
                        options={['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']}
                        renderInput={(params) => (
                            <TextField {...params} label="States" placeholder="States" />
                        )}
                    />
                </Stack>
                <Box mt={2}>
                    <Button variant="contained" onClick={saveDirector} endIcon={<SaveIcon />}>Save</Button>
                </Box>
            </Box>
        </>
    );
}
