import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Spinner,
  Button,
  CustomInput,
  Input,
  FormGroup
} from "reactstrap";
import Loading from "../components/Loading";
import DisciplineTypesStatusSelect from "../components/DisciplineTypesStatusSelect";
import AddressAutocomplete from "../components/AddressAutocomplete";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import "../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

export const Patients = ({ therapists, directorsAndZipcodes }) => {
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [activeSelect, setActiveSelect] = useState(true);
  const [NASelect, setNASelect] = useState(false);
  const [dischargedSelect, setDischargedSelect] = useState(false);
  const [directorSort, setDirectorSort] = useState("");
  const [expandedColumns, setExpandedColumns] = useState(false);
  const [awaitingScriptSelected, setAwaitingScriptSelected] = useState(false);
  const [pendingSelected, setPendingSelected] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getPatients = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);

        const response = await fetch(`${apiOrigin}/api/patients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();
        setPatients(responseData);
        // setPatients(
        //   responseData.map((x) => {
        //     const patient = x;
        //     // patient.disciplinesAndStatuses = JSON.parse(
        //     //   x.disciplinesAndStatuses
        //     // );
        //     return patient;
        //   })
        // );
        setLoading(false);
      } catch (error) {
        alert("error: " + error);
      }
    };

    getPatients();
  }, []);

  const DisciplinesAndStatusesFormatter = (cell, row) => {
    if (cell) {
      return (
        <span>
          {cell.map((x, i) => (
            <p key={i}>
              <strong
                key={i}
                style={{
                  marginLeft: "15px",
                  color: x.Status === "PENDING" ? "#d23d3d" : (x.Status === "ACTIVE" ? "#50a250" : (x.Status === "DISCHARGED" ? "#484646" : "#9a9a9a")),
                }}
              >
                {x.Type} &#x25E6; {x.Status} &#x25E6; {new Date(x.Date).toLocaleString().split(',')[0]}{x.TherapistId && <span> &#x25E6; </span>} {getFullName(therapists.filter(t => t.rowKey === x.TherapistId)[0])}
              </strong>
            </p>
          ))}
        </span>
      );
    }
    return <span></span>;
  };


  const getFullName = (therapist) => therapist ? therapist.lastName + " " + therapist.firstName : "";

  const columns = [
    {
      dataField: "rowKey",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "directorId",
      text: "Director",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "225px" };
      },
      formatter: (cell, row) => <span>{cell && row.isDirector !== "true" ? getFullName(therapists.filter(x => x.rowKey === cell)[0]) : ""}</span>,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <Input
            type="select"
            name="directorsSelet"
            id="directorsSelet"
            defaultValue={value ? value : ""}
            onChange={(e) => editorProps.onUpdate(e.target.value)}
          >
            <option value="">Select Director</option>
            {therapists.filter(x => x.isDirector === "true").map((x, i) => <option key={i} value={x.rowKey}>{getFullName(x)}</option>)}
          </Input>
        );
      }
    },
    {
      dataField: "isFacility",
      text: "Is Facility",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      },
      formatter: (cell, row) => <FormGroup check><Input readOnly type="checkbox" checked={cell === "true"} /></FormGroup>,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <FormGroup check><Input type="checkbox" checked={value === "true"} 
          onChange={(e) => {
              let checked = e.target.checked;
              const message = "By changing " + (checked ? "to" : "from") + " facility patient, this patient will be " + 
                (checked ? "excluded" : "included") + "from the daily pairing process and from the bi-weekly discharged process. Continue?";
              checked = window.confirm(message) ? checked : !checked;
              editorProps.onUpdate(checked.toString())
            }
          } /></FormGroup>
        );
      },
      csvText: 'isFacility'
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "180px" };
      },
    },
    {
      dataField: "fullAddress",
      text: "Full Address",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "320px" };
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const setAutoCompleteAddress = (fullAddress, address1, city, state, zip) => {
          setAddress1(address1);
          setCity(city);
          setState(state);
          setZip(zip);
          editorProps.onUpdate(fullAddress);
        }
        return (
          <AddressAutocomplete setAddress={setAutoCompleteAddress} value={value} />
        );
      },
      sortValue: (cell, row) => cell ? cell.split(',')[1].trim() : ""
    },
    {
      dataField: "disciplinesAndStatuses",
      text: "Discipline Types",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "450px" };
      },
      formatter: DisciplinesAndStatusesFormatter,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => <DisciplineTypesStatusSelect fullUpdate={editorProps.onUpdate} value={value} getFullName={getFullName} therapists={therapists} />
      ,
    },
    {
      dataField: "createdDate",
      text: "Created Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      formatter: (cell) => {
        if (!cell) return;
        let dateObj = new Date(cell);
        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "address1",
      text: "Address1",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "320px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "address2",
      text: "Address2",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "zip",
      text: "Zip",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "dob",
      text: "DOB",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      formatter: (cell) => {
        if (!cell) return;
        let dateObj = new Date(cell);
        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editor: {
        type: Type.DATE
      },
      hidden: !expandedColumns
    },
    {
      dataField: "ssn",
      text: "SSN",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "500px" };
      },
      editor: {
        type: Type.TEXTAREA
      },
      hidden: !expandedColumns
    },
    {
      dataField: "primaryName",
      text: "Primary Ins. Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "primaryId",
      text: "Primary Ins. Id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "secondaryName",
      text: "Secondary Ins. Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "secondaryId",
      text: "Secondary Ins. Id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "doctor",
      text: "Doctor",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "doctorPhone",
      text: "DoctorPhone",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "170px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "doctorFax",
      text: "Doctor Fax",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "partBDeductible",
      text: "Part B Deductible",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "partBUsedOT",
      text: "Part B User OT",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "180px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "partBUserPT_SLP",
      text: "Part B User PT/SLP",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "referralSource",
      text: "Referral Source",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "smsExtendedText",
      text: "SMS Extended Text",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns,
      validator: (newValue, row, column) => {
        if(newValue.length > 15) {
          return {
            valid: false,
            message: "max length is 15 characters"
          };
        }
        return true;
      }
    },
  ];

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    if (oldValue === newValue) return false;
    setLoading(true);

    if (column.dataField === "fullAddress") {
      row.address1 = address1;
      row.city = city;
      row.state = state;
      row.zip = zip;
    }

    row[column.dataField] = newValue;
    const updatedPatient = { ...row };

    updatedPatient.disciplinesAndStatuses = JSON.stringify(
      updatedPatient.disciplinesAndStatuses
    );

    fetch(`${apiOrigin}/api/patients/${row.rowKey}`, {
      method: "PUT",
      body: JSON.stringify(updatedPatient),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        done(true);
        setLoading(false);
      })
      .catch((error) => {
        alert("error: " + error);

        done(false);
        setLoading(false);
      });

    setLoading(false);

    return { async: true };
  };

  const { SearchBar } = Search;

  const getPatientsToDisplay = () =>
    patients.filter(p => (directorSort === "" || p.directorId == directorSort) &&
      (p.disciplinesAndStatuses.length === 0 ||
        (activeSelect && p.disciplinesAndStatuses.filter(x => x.Status === "ACTIVE").length > 0) ||
        (NASelect && p.disciplinesAndStatuses.filter(x => x.Status === "N/A").length > 0) ||
        (awaitingScriptSelected && p.disciplinesAndStatuses.filter(x => x.Status === "AWAITING-SCRIPT").length > 0) ||
        (pendingSelected && p.disciplinesAndStatuses.filter(x => x.Status === "PENDING").length > 0) ||
        (dischargedSelect && p.disciplinesAndStatuses.filter(x => x.Status === "DISCHARGED").length > 0)));

  return (
    <>
      <div style={{ textAlign: "center", color: "#238dcf" }}><h1>Patients {patients.length}</h1></div>
      <hr />

      <ToolkitProvider
        keyField="rowKey"
        data={getPatientsToDisplay()}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <Button color="secondary" onClick={() => setExpandedColumns(!expandedColumns)}>
                    {expandedColumns ? "Condense" : "Expand"}
                  </Button>
                </div>
                <div style={{ display: "flex" }}>
                  <SearchBar {...props.searchProps} />
                  <Input
                    type="select"
                    name="directorSort"
                    id="directorSort"
                    defaultValue={directorSort}
                    onChange={(e) => setDirectorSort(e.target.value)}
                    style={{ marginLeft: "30px" }}
                  >
                    <option value="">All Directors</option>
                    {directorsAndZipcodes.map((x, i) => <option key={i} value={x.rowKey}>{x.lastName + " " + x.firstName}</option>)}
                  </Input>
                </div>
                <CustomInput type="checkbox" className="checkbox-ml" id="activeSelect" name="activeSelect" label="Active" checked={activeSelect} onChange={() => setActiveSelect(!activeSelect)} />{" "}
                <CustomInput type="checkbox" className="checkbox-ml" id="NASelect" name="NASelect" label="N/A" checked={NASelect} onChange={() => setNASelect(!NASelect)} />{" "}
                <CustomInput type="checkbox" className="checkbox-ml" id="dischargedSelect" name="dischargedSelect" label="Discharged" checked={dischargedSelect} onChange={() => setDischargedSelect(!dischargedSelect)} />{" "}
                <CustomInput type="checkbox" className="checkbox-ml" id="awaitingScriptSelected" name="awaitingScriptSelected" label="Awaiting Script" checked={awaitingScriptSelected} onChange={() => setAwaitingScriptSelected(!awaitingScriptSelected)} />{" "}
                <CustomInput type="checkbox" className="checkbox-ml" id="pendingSelected" name="pendingSelected" label="Pending" checked={pendingSelected} onChange={() => setPendingSelected(!pendingSelected)} />{" "}
              </div>
              <BootstrapTable
                keyField="rowKey"
                data={patients}
                columns={columns}
                striped
                hover
                condensed
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  beforeSaveCell,
                })}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>

      {loading && <Spinner animation="border" role="status" />}
    </>
  );
};

export default withAuthenticationRequired(Patients, {
  onRedirecting: () => <Loading />,
});
