import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { DISCIPLINE_TYPES } from "../constants";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import Loading from "../components/Loading";
import AddressAutocomplete from "../components/AddressAutocomplete";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import CSVReader from "react-csv-reader";

import "../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

export const Therapists = ({ directorsAndZipcodes }) => {
  const [selectedTherapistIds, setSelectedTherapistIds] = useState([]);
  const [director, setDirector] = useState("");
  const [isDirector, setIsDirector] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [distanceMiles, setDistanceMiles] = useState("");
  const [distanceMinutes, setDistanceMinutes] = useState("");
  const [CSVdata, setCSVdata] = useState([]);

  const [therapists, setTherapists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [modal, setModal] = useState(false);
  const [zipCodesModal, setZipCodesModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const zipCodesModalToggle = () => setZipCodesModal(!zipCodesModal);

  const toggle = () => {
    setDirector("");
    setIsDirector(false);
    setFirstName("");
    setLastName("");
    setFullAddress("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("");
    setZip("");
    setDiscipline("");
    setPhone("");
    setEmail("");
    setDistanceMiles("");
    setDistanceMinutes("");
    setModal(!modal);
  };

  useEffect(() => {
    const getTherapists = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);

        const response = await fetch(`${apiOrigin}/api/therapists`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();
        //console.log(responseData);
        setTherapists([...responseData]);
        setLoading(false);
      } catch (error) {
        alert("error: " + error);
      }
    };

    getTherapists();
  }, []);

  // CRUD operations
  const addTherapist = async () => {
    //if (!name || !therapistname) return;

    setLoading(true);

    try {
      const response = await fetch(`${apiOrigin}/api/therapists`, {
        method: "POST",
        body: JSON.stringify({
          firstName,
          lastName,
          phone,
          email,
          discipline,
          fullAddress,
          address1,
          address2,
          city,
          state,
          zip,
          distanceMiles,
          distanceMinutes,
          director,
          isDirector,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = await response.json();
      setTherapists([...therapists, responseData]);
    } catch (error) {
      alert("error: " + error);
    }

    setLoading(false);
    toggle();
  };

  const addBatch = async () => {
    const isTherapists = CSVdata.filter((x) => x.fullAddress).length > 0;
    const isZipCodes = CSVdata.filter((x) => x.zipCodes).length > 0;

    if (!isTherapists && !isZipCodes) {
      alert("CSV file schema wrong");
      return;
    } else {
      const res = window.confirm(
        "Upload " +
          (isZipCodes ? "directors and zipcodes" : "therapists") +
          " csv file?"
      );
      if (!res) return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${apiOrigin}/api/` +
          (isZipCodes ? "directorsAndZipCodes" : "therapists") +
          "/batch",
        {
          method: "POST",
          body: JSON.stringify(CSVdata),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = await response.json();
      if (response.status === 400) {
        alert(responseData.msg);
        return;
      }

      if (isTherapists) setTherapists([...therapists, ...responseData]);

      alert("Data was added!");
    } catch (error) {
      alert("error: " + error);
    }

    setLoading(false);
    setCSVdata([]);
  };

  const deleteTherapist = async () => {
    if (selectedTherapistIds.length === 0) return;
    else if (
      selectedTherapistIds.filter(
        (t) => directorsAndZipcodes.filter((d) => d.rowKey == t).length > 0
      ).length > 0
    ) {
      alert(
        "Cannot delete director(s) that is part of zipcodes table. Remove the director for that table first."
      );
      return;
    } else {
      const conf = window.confirm(
        "Are you sure you want to delete selected therapists?"
      );
      if (!conf) return;
    }

    setLoading(true);

    setTherapists(
      therapists.filter(
        (therapist) =>
          selectedTherapistIds.filter((x) => therapist.rowKey === x).length ===
          0
      )
    );

    try {
      await fetch(
        `${apiOrigin}/api/therapists?ids=${selectedTherapistIds.join(",")}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      alert("error: " + error);
    }

    setLoading(false);
  };

  const setAutoCompleteAddress = (fullAddress, address1, city, state, zip) => {
    setFullAddress(fullAddress);
    setAddress1(address1);
    setCity(city);
    setState(state);
    setZip(zip);
  };

  const directorsAndZipcodesColumns = [
    {
      dataField: "rowKey",
      text: "ID",
      hidden: true,
      searchable: false,
      csvExport: false,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "140px" };
      },
      csvText: "firstName",
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "140px" };
      },
      csvText: "lastName",
    },
    {
      dataField: "zipCodes",
      text: "ZipCodes",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "30000px" };
      },
      csvText: "zipCodes",
    },
  ];

  const columns = [
    {
      dataField: "rowKey",
      text: "ID",
      hidden: true,
      searchable: false,
      csvExport: false,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "140px" };
      },
      csvText: "firstName",
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "140px" };
      },
      csvText: "lastName",
    },
    {
      dataField: "fullAddress",
      text: "Full Address",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "370px" };
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const setAutoCompleteAddress = (
          fullAddress,
          address1,
          city,
          state,
          zip
        ) => {
          setAddress1(address1);
          setCity(city);
          setState(state);
          setZip(zip);
          editorProps.onUpdate(fullAddress);
        };
        return (
          <AddressAutocomplete
            setAddress={setAutoCompleteAddress}
            value={value}
          />
        );
      },
      sortValue: (cell, row) => (cell ? cell.split(",")[1].trim() : ""),
      csvText: "fullAddress",
    },
    {
      dataField: "discipline",
      text: "Discipline",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "125px" };
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <Input
            type="select"
            name="discipline"
            id="discipline"
            defaultValue={value}
            onChange={(e) => editorProps.onUpdate(e.target.value)}
          >
            <option value="">Select Discipline</option>
            {DISCIPLINE_TYPES.map((x, i) => (
              <option key={i} value={x}>
                {x}
              </option>
            ))}
          </Input>
        );
      },
      csvText: "discipline",
    },
    {
      dataField: "director",
      text: "Director",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "225px" };
      },
      formatter: (cell, row) => (
        <span>
          {cell && row.isDirector !== "true"
            ? getFullName(therapists.filter((x) => x.rowKey === cell)[0])
            : ""}
        </span>
      ),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        if (row.isDirector) return;
        return (
          <Input
            type="select"
            name="directorsSelet"
            id="directorsSelet"
            defaultValue={value ? value : ""}
            onChange={(e) => editorProps.onUpdate(e.target.value)}
          >
            <option value="">Select Director</option>
            {therapists
              .filter((x) => x.isDirector)
              .map((x, i) => (
                <option key={i} value={x.rowKey}>
                  {getFullName(x)}
                </option>
              ))}
          </Input>
        );
      },
      csvFormatter: (cell, row, rowIndex) =>
        cell ? getFullName(therapists.filter((t) => t.rowKey === cell)[0]) : "",
      csvText: "director",
    },
    {
      dataField: "isDirector",
      text: "Is Director",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      },
      formatter: (cell, row) => (
        <FormGroup check>
          <Input readOnly type="checkbox" checked={cell === "true"} />
        </FormGroup>
      ),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <FormGroup check>
            <Input
              type="checkbox"
              checked={value === "true"}
              onChange={(e) =>
                editorProps.onUpdate(e.target.checked.toString())
              }
            />
          </FormGroup>
        );
      },
      csvText: "isDirector",
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      csvText: "phone",
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "280px" };
      },
      csvText: "email",
    },
    {
      dataField: "distanceMiles",
      text: "DistanceMiles",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px" };
      },
      csvText: "distanceMiles",
    },
    {
      dataField: "distanceMinutes",
      text: "DistanceMinutes",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "180px" };
      },
      hidden: true,
      csvText: "distanceMinutes",
    },
    {
      dataField: "isDisabled",
      text: "Disabled",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      },
      formatter: (cell, row) => (
        <FormGroup check>
          <Input readOnly type="checkbox" checked={cell === "true"} />
        </FormGroup>
      ),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <FormGroup check>
            <Input
              type="checkbox"
              checked={value === "true"}
              onChange={(e) =>
                editorProps.onUpdate(e.target.checked.toString())
              }
            />
          </FormGroup>
        );
      },
      csvText: "isDisabled",
    },
    {
      dataField: "address1",
      text: "Address1",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
      editable: false,
      csvText: "address1",
    },
    {
      dataField: "address2",
      text: "Address2",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      csvText: "address2",
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      editable: false,
      csvText: "city",
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      editable: false,
      csvText: "state",
    },
    {
      dataField: "zip",
      text: "Zip",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      editable: false,
      csvText: "zip",
    },
  ];

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    if (oldValue === newValue) return false;
    setLoading(true);

    if (column.dataField === "fullAddress") {
      row.address1 = address1;
      row.city = city;
      row.state = state;
      row.zip = zip;
    }

    row[column.dataField] = newValue;
    const updatedTherapist = { ...row };

    //console.log(updatedTherapist);
    fetch(`${apiOrigin}/api/therapists/${row.rowKey}`, {
      method: "PUT",
      body: JSON.stringify(updatedTherapist),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        done(true);
        setLoading(false);
      })
      .catch((error) => {
        alert("error: " + error);

        done(false);
        setLoading(false);
      });

    return { async: true };
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    clickToEdit: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedTherapistIds([...selectedTherapistIds, row.rowKey]);
      } else {
        setSelectedTherapistIds(
          selectedTherapistIds.filter((x) => x !== row.rowKey)
        );
      }
    },
    onSelectAll: (isSelect, rows) => {
      const ids = rows.map((r) => r.rowKey);
      if (isSelect) {
        setSelectedTherapistIds(ids);
      } else {
        setSelectedTherapistIds([]);
      }
    },
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) =>
      header
        //.toLowerCase()
        .replace(/\W/g, "_"),
  };

  const fileLoad = (data, fileInfo) => {
    setCSVdata(data);
  };

  const { SearchBar } = Search;

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-success" onClick={handleClick}>
          Export to CSV
        </button>
      </div>
    );
  };

  const getFullName = (therapist) =>
    therapist ? therapist.lastName + " " + therapist.firstName : "";

  return (
    <>
      <div style={{ textAlign: "center", color: "#238dcf" }}>
        <h1>Therapists</h1>
      </div>
      <hr />

      <ToolkitProvider
        keyField="rowKey"
        data={therapists}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <Button color="primary" onClick={toggle}>
                  Add +
                </Button>{" "}
                <Button color="danger" onClick={deleteTherapist}>
                  Delete -
                </Button>
                <CSVReader
                  cssInputClass="csv_input"
                  label="Select csv file"
                  parserOptions={papaparseOptions}
                  onFileLoaded={(data, fileInfo) => fileLoad(data, fileInfo)}
                />
                {CSVdata.length > 0 && (
                  <Button
                    style={{ marginLeft: "30px" }}
                    color="primary"
                    onClick={addBatch}
                  >
                    Add Bulk
                  </Button>
                )}
              </div>

              <div style={{ display: "flex" }}>
                <SearchBar {...props.searchProps} />

                <Button
                  style={{ marginLeft: "30px" }}
                  color="primary"
                  onClick={zipCodesModalToggle}
                >
                  Show Directors And Zipcodes
                </Button>

                <MyExportCSV {...props.csvProps} />
              </div>
            </div>

            <BootstrapTable
              keyField="rowKey"
              data={therapists}
              columns={columns}
              striped
              hover
              condensed
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                beforeSaveCell,
              })}
              selectRow={selectRow}
              pagination={paginationFactory()}
              {...props.baseProps}
              className="table-sm"
            />
          </div>
        )}
      </ToolkitProvider>

      {loading && <Spinner animation="border" role="status" />}

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Add Therapist</ModalHeader>

        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="firstName" sm={2}>
                FirstName
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="FirstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastName" sm={2}>
                LastName
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="LastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="fullAddress" sm={2}>
                Full Address
              </Label>
              <Col sm={10}>
                <AddressAutocomplete setAddress={setAutoCompleteAddress} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="address1" sm={2}>
                Address1
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Address1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="address2" sm={2}>
                Address2
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Address2"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="city" sm={2}>
                City
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="state" sm={2}>
                State
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="zip" sm={2}>
                Zip
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="zip"
                  id="zip"
                  placeholder="Zip"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>
                Phone
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) || e.target.value === "+")
                      setPhone(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={2}>
                Email
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="discipline" sm={2}>
                Discipline
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="discipline"
                  id="discipline"
                  defaultValue={discipline}
                  onChange={(e) => setDiscipline(e.target.value)}
                >
                  <option value="">Select Discipline</option>
                  {DISCIPLINE_TYPES.map((x, i) => (
                    <option key={i} value={x}>
                      {x}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="distanceMiles" sm={2}>
                DistanceMiles
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="distanceMiles"
                  id="distanceMiles"
                  placeholder="DistanceMiles"
                  value={distanceMiles}
                  onChange={(e) => setDistanceMiles(e.target.value)}
                />
              </Col>
            </FormGroup>
            {/* <FormGroup row>
              <Label for="distanceMinutes" sm={2}>
                DistanceMinutes
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="distanceMinutes"
                  id="distanceMinutes"
                  placeholder="DistanceMinutes"
                  value={distanceMinutes}
                  onChange={(e) => setDistanceMinutes(e.target.value)}
                />
              </Col>
            </FormGroup> */}
            <FormGroup row>
              <Label for="director" sm={2}>
                Director
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="director"
                  id="director"
                  defaultValue={director ? director : ""}
                  onChange={(e) => setDirector(e.target.value)}
                >
                  <option value="">Select Director</option>
                  {therapists
                    .filter((x) => x.isDirector)
                    .map((x, i) => (
                      <option key={i} value={x.rowKey}>
                        {getFullName(x)}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={10}>
                <CustomInput
                  type="checkbox"
                  id="isDirector"
                  name="isDirector"
                  label="Is Director"
                  onChange={(e) => setIsDirector(!isDirector)}
                  checked={isDirector}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={addTherapist}>
            Add
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={zipCodesModal} toggle={zipCodesModalToggle} size="lg">
        <ModalHeader toggle={zipCodesModalToggle}>
          Directors And ZipsCodes
        </ModalHeader>

        <ModalBody>
          <ToolkitProvider
            keyField="rowKey"
            data={directorsAndZipcodes}
            columns={directorsAndZipcodesColumns}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <div style={{ display: "flex", alignContent: "space-between" }}>
                  <SearchBar {...props.searchProps} />
                  <MyExportCSV {...props.csvProps} />
                </div>
                <BootstrapTable
                  keyField="rowKey"
                  data={directorsAndZipcodes}
                  columns={directorsAndZipcodesColumns}
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={zipCodesModalToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withAuthenticationRequired(Therapists, {
  onRedirecting: () => <Loading />,
});
