import React, { useState, useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import Paper from '@mui/material/Paper';
import { Popup, Position } from "devextreme-react/popup";
import PatientsDxGrid from "./PatientDxGrid";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { PatientDrawerContext } from '../../App'
import PatientDetails from "../../components/PatientDetails";
import { PatientFilter } from "./PatientFilter";


const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const useStyles = makeStyles({
  paper: {
    width: "100%",
    height: "100%"
  },
  card: {
    backgroundColor: 'blue'
  }
});

const serviceUrl = `${apiOrigin}/api`;

export const PatientsList = ({ therapistsData, directorsAndZipcodes }) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState();
  const [selectedRowIndex, setselectedRowIndex] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [showNotes, setShowNotes] = useState(true);
  const [filter, setFilter] = useState();
  const [filterPopupVisible, setFilterPopupVisible] = useState(false);
  const { setPreviewPatient, reloadData } = useContext(PatientDrawerContext);

  const [remoteDataSource, setRemoteDataSource] = useState();

  const onFocusedRowChanged = (e) => {
    const dataRow = e.row && e.row.data;
    setselectedRowIndex(e.rowIndex);
    setSelectedPatient(dataRow);
  };

  function setFilterDoNotRefreshGrid(filter) {
    setFilter(filter);
  }

  function gridSetPreviewPatient(patient) {
    setPreviewPatient(patient);
  }

  useEffect(() => {
    const getPatients = async () => {
      const token = await getAccessTokenSilently();
      setRemoteDataSource(createStore({
        key: "patientId",
        loadUrl: serviceUrl + "/patientsV1",
        onBeforeSend: (e, s) => {
          s.headers = {
            Authorization: `Bearer ${token}`,
          };
          s.data.jsonFilter = JSON.stringify(filter); // '{"FollowUpType" : "All","DisciplineStatus" : ["ACTIVE"],"DisciplineType" : "OT"}';
        },
      }));
      setToken(token);
    };
    getPatients();
  }, [filter, reloadData]);

  return (
    <>
      <Box display='flex' height='97%' width='100%' flexDirection='row-reverse'>
        {showNotes && (
          <Box display='flex' flexDirection='column' width='100%' mx={1} height='89vh' flex={1} minWidth='400px'>
            <Typography variant="h6" my={1} align="center" mb={1}>
              <span>
                {selectedPatient &&
                  selectedPatient.firstName + " " + selectedPatient.lastName}
              </span>
            </Typography>

            <Paper elevation={3} className={classes.paper}>
              <Box m={1} height='100%'>
                <PatientDetails
                  patientsId={selectedPatient?.patientId}
                  therapistsData={therapistsData}
                />
              </Box>
            </Paper>
          </Box>
        )}

        <Box m={1} height='100%' flex={3} width='calc(100vh - 300px)'>
          <PatientsDxGrid remoteDataSource={remoteDataSource}
            onFocusedRowChanged={onFocusedRowChanged}
            setFilterPopupVisible={setFilterPopupVisible}
            setShowNotes={setShowNotes}
            showNotes={showNotes}
            setFilter={setFilterDoNotRefreshGrid}
            filter={filter}
            previewPatient={gridSetPreviewPatient} />
        </Box>



        <Popup
          visible={filterPopupVisible}
          onHiding={() => setFilterPopupVisible(false)}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title="Filter"
          container=".dx-viewport"
          width={400}
          height={380}
        >
          <Position at="center" my="center" of="window" />
          <PatientFilter
            setFilter={setFilter}
            filter={filter}
            flexDirection="column"
            isPopup
          />
        </Popup>
      </Box>
    </>
  );
};

export default withAuthenticationRequired(PatientsList, {
  //onRedirecting: () => <Loading />,
});
