import React, { useState, useEffect, useRef } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import { createStore } from "devextreme-aspnet-data-nojquery";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  RemoteOperations,
  HeaderFilter,
  ColumnChooser,
  Sorting,
  ColumnFixing,
  FilterPanel,
  FilterBuilderPopup,
  Export,
} from "devextreme-react/data-grid";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;
const serviceUrl = `${apiOrigin}/api`;

export const DisciplinesGrid = ({ patientsId, isBasic, editDiscipline }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState();
  const formRef = useRef();

  const remoteDataSource = createStore({
    key: "disciplinesAndStatusesId",
    loadUrl: serviceUrl + "/PatientDisciplines",
    onBeforeSend: (e, s) => {
      s.headers = {
        Authorization: `Bearer ${token}`,
      };
    },
    //sample how to filter by patient id
    loadParams: { patientId: patientsId },
  });

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    getToken();
  }, []);

  const onFocusedRowChanged = (e) => {
    const dataRow = e.row && e.row.data;
  };

  function handleDoubleClick(e) {
    if (!editDiscipline || !e.data) {
      return;
    }

    editDiscipline({
      ...(e.data),
      patientId: patientsId
    });
  }

  return (
      <DataGrid
        remoteOperations
        dataSource={remoteDataSource}
        height="100%"
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders={!isBasic}
        columnResizingMode={"widget"}
        showColumnLines={false}
        onRowDblClick={handleDoubleClick}
        rowAlternationEnabled
        focusedRowEnabled
        autoNavigateToFocusedRow
        onFocusedRowChanged={onFocusedRowChanged}
      >
        <RemoteOperations groupPaging={true} />
        <SearchPanel visible={!isBasic} width={250} />
        <HeaderFilter visible={!isBasic} />
        <ColumnChooser enabled={!isBasic} />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} visible={!isBasic} />
        <Pager showPageSizeSelector visible={!isBasic} />
        <FilterPanel visible={!isBasic} />
        <FilterBuilderPopup />
        <Export enabled={!isBasic} />

        <Column dataField="disciplinesAndStatusesId" visible={false} />
        <Column dataField="rowKey" visible={false} />
        <Column dataField="patientName" visible={!isBasic} />
        <Column dataField="patientId" />
        <Column dataField="type" caption='Discipline'/>
        <Column dataField="status" />
        <Column dataField="therapist" />
        <Column dataField="referralSource" />
        <Column dataField="isFacility" />
        <Column dataField="addedOn" dataType="date" format="shortDateShortTime"/>
        <Column dataField="addedBy" />
        <Column dataField="modifiedBy" />
        <Column dataField="modifiedOn" dataType="date" format="shortDateShortTime"/>
        <Column dataField="date" visible={false} dataType="date" format="shortDateShortTime"/>
        <Column dataField="therapistId" visible={false} />
        <Column dataField="statusLastUpdated" dataType="date" format="shortDateShortTime"/>
        <Column dataField="daysStatusLastUpdated" />
        <Column dataField="director" />
        <Column dataField="fullAddress" />
        <Column dataField="patientFirstName" />
        <Column dataField="patientLastName" />
        <Column dataField="dob" dataType="date" format="shortDate"/>
        <Column dataField="facilityName" />
      </DataGrid>
  );
};

export default withAuthenticationRequired(DisciplinesGrid, {
  //onRedirecting: () => <Loading />,
});
