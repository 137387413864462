import React, { useState, useEffect, useRef, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Form, {
    SimpleItem,
    Label,
    GroupItem,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import { Box, Button, Divider, FormControlLabel, Grid, Link, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material";
import { Confirm } from "mui-extension";
import SaveIcon from '@mui/icons-material/Save';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DxAddressAutoComplete from '../../components/DxAddressAutocomplete';
import useAxiosHelpers from "../../components/useAxiosHelpers";
import { UserProfileContext } from "../../App";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;
/* global google */

const blankCreditCard = {
    creditCard: "",
    nameOnCard: "",
    expireMonth: "",
    expireYear: "",
    cvc: "",
    billingZip: ""
}

export default function PatientEdit(props) {
    const { directorsAndZipcodes, patientId, reloadData, onClose, showSaveAndClose } = props;
    const [patient, setPatient] = useState({ isFacility: false });
    const [patientCreditCard, setPatientCreditCard] = useState(blankCreditCard);
    const [referralSource, setReferralSource] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [saveCreditCard, setSaveCreditCard] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState();
    const autocompleteInput = useRef();
    const formRef = useRef();
    const axiosHelpers = useAxiosHelpers();
    const userProfile = useContext(UserProfileContext);

    useEffect(() => {
        const getPatient = async () => {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            setToken(token);
            const config = { headers: { Authorization: `Bearer ${token}` } };
            try {
                let data = await axios.get(`${apiOrigin}/api/patients/${patientId}`, config);
                setPatient(data.data);
                setIsLoading(false);
                if (props.setPatientCallBack) {
                    props.setPatientCallBack(data.data);
                }
            } catch (ex) {
                axiosHelpers.handleError(ex);
                console.log(ex);
            } finally {
                setIsLoading(false);
            }
        };

        const getFacilityAndReferrals = async () => {
            try {
                const token = await getAccessTokenSilently();
                setToken(token);
                const config = { headers: { Authorization: `Bearer ${token}` } };
                let referralSource = await axios.get(`${apiOrigin}/api/ReferralSourcesList`, config);
                setReferralSource(referralSource.data.sort((a, b) => a.name?.localeCompare(b.name)));

                let facilities = await axios.get(`${apiOrigin}/api/FacilitiesList`, config);
                setFacilities(facilities.data);
            } catch (ex) {
                axiosHelpers.handleError(ex);
            } finally {
                setIsLoading(false);
            }
        }

        getFacilityAndReferrals();

        if (patientId) {
            getPatient();
        }
    }, [patientId]);

    const savePatient = (close) => async () => {
        const token = await getAccessTokenSilently();
        try {
            if (!patient.referralSourceId && !patient.isFacility) {
                axiosHelpers.handleError({ message: "Please select a Referral Source" })
                return;
            }
            setIsLoading(true);
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const payload = { patient }
            payload.saveCreditCard = saveCreditCard;
            if (saveCreditCard) {
                payload.patientCreditCard = patientCreditCard;
            }

            let patientSavedResults;
            if (!patient?.patientId) {
                let data = await axios.post(`${apiOrigin}/api/patients/`, payload, config);
                if (!close) {
                    setPatient(data.data);
                    patientSavedResults = data.data;
                }
            } else {
                let data = await axios.put(`${apiOrigin}/api/patients/${patient.rowKey}`, payload, config);
                setPatient(data.data);
                patientSavedResults = data.data;
            }

            if (close) {
                onClose();
            }
            setSaveCreditCard(false);
            setPatientCreditCard(blankCreditCard);
            if (reloadData)
                reloadData();

            if (props.onSaveCallBack) {
                props.onSaveCallBack(patientSavedResults);
            }
        } catch (ex) {
            axiosHelpers.handleError(ex);
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    const deletePatient = async () => {
        if (!patient?.patientId) {
            return;
        }
        const token = await getAccessTokenSilently();

        try {
            setIsLoading(true);
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.delete(`${apiOrigin}/api/patients/${patientId}`, config);
            if (props.onSaveCallBack) {
                props.onSaveCallBack("deleted");
            }
            if (onClose)
                onClose();
            if (reloadData)
                reloadData();
        } catch (ex) {
            axiosHelpers.handleError(ex);
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    const formFieldDataChanged = (e) => {
        const p = { [e.dataField]: e.value, ...patient };
        setPatient(p);
    }

    const setAutoCompleteAddress = (fullAddress, address1, city, state, zip) => {
        setPatient((prevState) => ({
            ...prevState,
            fullAddress: fullAddress,
            address1,
            city,
            state,
            zip,
        }));
        let directorOpt = directorsAndZipcodes.filter((x) => x.states?.includes(state));
        if (!directorOpt) {
            directorOpt = directorsAndZipcodes.filter((x) => x.zipCodes.includes(zip));
        }
        if (directorOpt.length > 0) {
            const director = directorOpt[0];
            setPatient((prevState) => ({
                ...prevState,
                directorId: director.rowKey,
            }));
        }
    }

    function avatarRender(d, dd) {
        return (
            <DxAddressAutoComplete
                value={patient?.fullAddress}
                setAddress={setAutoCompleteAddress}
            />
        );
    }

    const onInputValueChanged = (e) => {
        setPatientCreditCard((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const updateCreditCard = async (e) => {
        try {
            setSaveCreditCard(e.target.checked)
            setIsLoading(true);
            if (userProfile.allowViewCC && patient.patientCreditCardId && e.target.checked) {
                const config = { headers: { Authorization: `Bearer ${token}` } };
                let data = await axios.get(`${apiOrigin}/api/PatientCreditCard/${patient.patientCreditCardId}`, config);
                setPatientCreditCard(data.data.creditCard);
            }
        } catch (ex) {
            axiosHelpers.handleError(ex);
        } finally {
            setIsLoading(false);
        }
    }

    const items = ['Self', 'Child', 'Other'];

    return (
        <>
            <Box mb={2} display='flex' justifyContent='flex-end' disabled={isLoading}>
                <Stack spacing={2} direction='row'>
                    {patient?.patientId &&
                        <Confirm title="Delete Patient?"
                            content="Are you sure you would like to delete this patient?"
                            onConfirm={deletePatient}>
                            <Button variant="contained" color="warning" disabled={isLoading} endIcon={<DeleteForeverIcon />}>Delete</Button>
                        </Confirm>
                    }
                    <Button variant="contained" onClick={savePatient(false)} disabled={isLoading} endIcon={<SaveIcon />}>Save</Button>
                    <Button variant="contained" onClick={savePatient(true)} disabled={isLoading} endIcon={<CloseFullscreenIcon />} hidden={!showSaveAndClose}>Save & Close</Button>
                </Stack>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Form
                        className="patientEdit--form"
                        colCount='auto'
                        labelMode="floating"
                        ref={formRef}
                        height='100%'
                        formData={patient}
                        scrollingEnabled
                        disabled={isLoading}
                        onFieldDataChanged={formFieldDataChanged}
                    >
                        <GroupItem>
                            <GroupItem caption="Patient Info">
                                <SimpleItem
                                    dataField="patientId"
                                    editorOptions={{ disabled: true }}
                                >
                                    <Label text="Patient ID"></Label>
                                </SimpleItem>
                                <SimpleItem dataField="firstName" />
                                <SimpleItem dataField="lastName" />
                                <SimpleItem
                                    dataField="dob"
                                    editorType="dxDateBox"
                                    editorOptions={{ useMaskBehavior: true }}
                                />
                                <SimpleItem
                                    dataField="ssn"
                                    editorOptions={{ mask: "000-000-0000" }}
                                />
                                <SimpleItem
                                    dataField="phone"
                                    editorOptions={{ mask: "+1 (000) 000-0000" }}
                                />
                                <SimpleItem
                                    dataField="directorId"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: directorsAndZipcodes.map((item) => {
                                            return {
                                                key: item.rowKey,
                                                data: item.firstName + " " + item.lastName,
                                            };
                                        }),
                                        displayExpr: "data",
                                        valueExpr: "key",
                                    }}
                                >
                                    <Label text="Director"></Label>
                                </SimpleItem>
                                <SimpleItem dataField="referralSourceId"
                                    editorType="dxSelectBox"
                                    visible={!patient.isFacility}
                                    editorOptions={{
                                        dataSource: referralSource,
                                        displayExpr: "name",
                                        valueExpr: "id",
                                        searchEnabled: true
                                    }}
                                >
                                    <Label text='Referral Source' />
                                </SimpleItem>
                                <SimpleItem dataField="followUp" editorType="dxDateBox" />
                                <SimpleItem dataField="smsExtendedText" />
                                <SimpleItem dataField="emailAddress" />
                            </GroupItem>

                            <GroupItem caption="Doctor">
                                <SimpleItem dataField="doctor" />
                                <SimpleItem dataField="doctorPhone" />
                                <SimpleItem dataField="doctorFax" />
                            </GroupItem>
                        </GroupItem>

                        <GroupItem>
                            <GroupItem caption="Address">
                                <SimpleItem
                                    dataField="isFacility"
                                    editorType="dxCheckBox"
                                />
                                <SimpleItem
                                    dataField="facilityId"
                                    editorType="dxSelectBox"
                                    visible={patient.isFacility}
                                    editorOptions={{
                                        dataSource: facilities,
                                        displayExpr: "displayName",
                                        valueExpr: "id",
                                        wrapItemText: true,
                                        searchEnabled: true
                                    }}
                                >
                                    <Label text="Facility"></Label>
                                </SimpleItem>

                                <SimpleItem
                                    render={avatarRender}
                                    visible={!patient.isFacility}
                                    dataField="fullAddress"
                                    innerRef={autocompleteInput}
                                />
                                <SimpleItem dataField="address2"
                                    visible={!patient.isFacility} />
                                <SimpleItem
                                    visible={!patient.isFacility}
                                    render={() => <div>
                                        City: {patient.city} State: {patient.state} Zip: {patient.zip}
                                    </div>} />
                            </GroupItem>

                            <GroupItem caption="Insurance">
                                <SimpleItem dataField="primaryName" />
                                <SimpleItem dataField="primaryId" />
                                <SimpleItem dataField="secondaryName" />
                                <SimpleItem dataField="secondaryId" />
                                <SimpleItem dataField="partBdeductible" caption='Part B Ddeductible'>
                                    <Label>Part B Ddeductible</Label>
                                </SimpleItem>
                                <SimpleItem dataField="partBusedOt" caption='Part B Used OT'>
                                    <Label text='Part B Used OT' />
                                </SimpleItem>
                                <SimpleItem dataField="partBuserPtSlp" caption='Part B Used PT/SLP' >
                                    <Label text='Part B Used PT/SLP' />
                                </SimpleItem>
                            </GroupItem>

                            <GroupItem caption='Responsible Party'>
                                <SimpleItem
                                    dataField="responsibility"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: items
                                    }}
                                >
                                    <Label text="Responsible Party"></Label>
                                </SimpleItem>
                                <SimpleItem dataField="responsibilityName" visible={patient.responsibility !== "Self"} />
                                <SimpleItem dataField="responsibilityEmail" visible={patient.responsibility !== "Self"} />
                                <SimpleItem dataField="responsibilityPhone"
                                    editorOptions={{ mask: "+1 (000) 000-0000" }}
                                    visible={patient.responsibility !== "Self"} />
                            </GroupItem>

                        </GroupItem>
                        {!patient?.patientId && (
                            <SimpleItem
                                colSpan={2}
                                dataField="notes"
                                editorType="dxTextArea"
                                editorOptions={{ height: 140 }}
                            />
                        )}
                    </Form>
                </Grid>

                <Grid item xs={3}>
                    <Stack spacing={2}>
                        <span className="dx-form-group-caption">Credit Card</span>
                        <Divider />
                        {patient.last4CreditCardNumber && <Typography > Credit card saved. Ending in {patient.last4CreditCardNumber}</Typography>}
                        {<FormControlLabel
                            label={!patient.patientId || !patient.last4CreditCardNumber ? "Add Credit Card" : "Update Credit Card"}
                            control={<Switch checked={saveCreditCard} onChange={updateCreditCard} />}
                        />}
                        {saveCreditCard && <>
                            <TextField label="Name On Card" value={patientCreditCard?.nameOnCard} name="nameOnCard" onChange={onInputValueChanged} />
                            <TextField label="Credit Card" value={patientCreditCard?.creditCard} name="creditCard" onChange={onInputValueChanged} />
                            <Stack direction="row" spacing={2}>
                                <TextField label="Expire Month" value={patientCreditCard?.expireMonth} name="expireMonth" onChange={onInputValueChanged} type="number" select fullWidth>
                                    <MenuItem value="1">01 - Jan</MenuItem>
                                    <MenuItem value="2">02 - Feb</MenuItem>
                                    <MenuItem value="3">03 - Mar</MenuItem>
                                    <MenuItem value="4">04 - Apr</MenuItem>
                                    <MenuItem value="5">05 - May</MenuItem>
                                    <MenuItem value="6">06 - Jun</MenuItem>
                                    <MenuItem value="7">07 - Jul</MenuItem>
                                    <MenuItem value="8">08 - Aug</MenuItem>
                                    <MenuItem value="9">09 - Sep</MenuItem>
                                    <MenuItem value="10">10 - Oct</MenuItem>
                                    <MenuItem value="11">11 - Nov</MenuItem>
                                    <MenuItem value="12">12 - Dec</MenuItem>
                                </TextField>
                                <TextField label="Expire Year" value={patientCreditCard?.expireYear} name="expireYear" onChange={onInputValueChanged} type="number" fullWidth />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <TextField label="CCV" value={patientCreditCard?.cvc} name="cvc" onChange={onInputValueChanged} type="number" />
                                <TextField label="Billing Zip" value={patientCreditCard?.billingZip} name="billingZip" onChange={onInputValueChanged} type="number" />
                            </Stack>
                        </>}
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}
