import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { PatientDrawerContext } from '../../App';
import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, Icon, IconButton, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { DISCIPLINE_STATUSES, DISCIPLINE_TYPES } from "../../constants";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { Confirm, Panel, PanelFoot, PanelHead } from "mui-extension";
import { Drawer, InputLabel } from "@material-ui/core";
import useAxiosHelpers from "../useAxiosHelpers";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

export default function DisciplineDialog({
  therapistData,
  patientsId,
  isOpen = false,
  initialData = null,
  onSuccess = () => { },
  closePopup = () => { },
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState();
  const [selectedTherapist, setSelectedTherapist] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { triggerReloadData } = useContext(PatientDrawerContext);
  const axiosHelpers = useAxiosHelpers();

  useEffect(() => {
    setData(initialData ?? { type: '', status: '', therapistId: '' });
    if (initialData?.therapistId) {
      const t = therapistData.find(t => t.rowKey === initialData.therapistId);
      if (t) {
        setSelectedTherapist({ ID: t.rowKey, Name: `${t.firstName} ${t.lastName}`, });
      }
    } else {
      setSelectedTherapist(null);
    }
  }, [patientsId, isOpen]);

  const saveNewDiscipline = async (disciplineData) => {
    if (!disciplineData.type) {
      axiosHelpers.handleError({ message: "Please select a type" })
      return;
    }

    const token = await getAccessTokenSilently();
    const config = { headers: { Authorization: `Bearer ${token}` } };

    disciplineData.DisciplinesAndStatusesId = initialData?.disciplinesAndStatusesId;
    disciplineData.patientId = patientsId;
    setIsLoading(true);
    try {
      if (patientsId) {
        const payload = {
          disciplinesAndStatusesId: disciplineData.disciplinesAndStatusesId,
          patientId: disciplineData.patientId,
          type: disciplineData.type,
          status: disciplineData.status,
          therapistId: disciplineData.therapistId ?? null
        }
        await axios.post(`${apiOrigin}/api/PatientDiscipline`, payload, config);
        triggerReloadData();
        onSuccess();
        closePopup();
      } else {
        console.error("no patient selected.");
      }
    } catch (ex) {
      axiosHelpers.handleError(ex);
      console.error("error updating discipline.");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDiscipline = async (id) => {
    if (!id) {
      return;
    }

    const token = await getAccessTokenSilently();
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      if (patientsId) {
        await axios.delete(`${apiOrigin}/api/PatientDiscipline/${id}`, config);
        triggerReloadData();
        onSuccess();
      } else {
        console.error("no patient selected.");
      }
    } catch (ex) {
      console.error(ex);
      console.error("error deleting discipline.");
    } finally {
      closePopup();
    }
  };

  const disciplineTherapists = therapistData
    ? therapistData.filter(item => item.discipline === data?.type)
      .map((item, index) => ({ ID: item.rowKey, Name: `${item.firstName} ${item.lastName}`, }))
      .sort((a, b) => a.Name.localeCompare(b.Name))
    : [];

  if (!data) return <div />

  return (
    isOpen && (
      <Drawer
        open={isOpen}
        anchor='right'
        onClose={(() => closePopup())}
        variant='temporary'
      >
        <Box width='500px' height='100%'>
          <Panel style={{ height: '100%' }}>
            <PanelHead action={
              <IconButton onClick={() => closePopup()}>
                <Icon>close</Icon>
              </IconButton>
            }>
              <Box display='flex' alignContent='center'>
                <Typography variant="h5" component="span">
                  {initialData ? "Edit Discipline" : "Add New Discipline"}
                </Typography>
              </Box>
            </PanelHead>
            <Box mt={5} style={{ height: '100%' }}>
              <Stack direction='column' justifyContent='space-between' height='100%' id='stack1'>
                <Stack mx={2} mt={2} spacing={3}>
                  <TextField
                    value={data?.patientsId ?? patientsId}
                    name="patientId"
                    disabled label="Patient Id"
                  />
                  <Stack direction='row' spacing={2} margin={0} mt={0} alignContent='center'>
                    <Stack justifyContent='center' >
                      <Typography variant="subtitle1" component="div">Type: </Typography>
                    </Stack>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'></FormLabel>
                      <RadioGroup row value={data.type} onChange={(e) => {
                        setData((prevState) => ({ ...prevState, type: e.target.value }));
                        setSelectedTherapist(null);
                      }}>
                        {DISCIPLINE_TYPES.map(t => <FormControlLabel key={t} value={t} control={<Radio />} label={t} />)}
                      </RadioGroup>
                    </FormControl>
                  </Stack>

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={data.status}
                      onChange={(e) => {
                        setData((prevState) => ({ ...prevState, status: e.target.value }));
                      }}
                      label="Status"
                    >
                      {DISCIPLINE_STATUSES.map(s => <MenuItem value={s}>{s}</MenuItem>)}
                    </Select>
                  </FormControl>

                  <Autocomplete
                    autoHighlight
                    options={disciplineTherapists}
                    value={selectedTherapist}
                    isOptionEqualToValue={(option, value) => option.ID === value}
                    getOptionLabel={t => t.Name}
                    onChange={(event, newValue) => {
                      setData((prevState) => ({ ...prevState, therapistId: newValue?.ID }));
                      setSelectedTherapist(newValue);
                    }}
                    label="Therapist"
                    clearIcon
                    renderInput={(params) => <TextField {...params} label="Therapist" />}
                  />
                </Stack>
                <Stack mx={2} mb={3}
                  direction="row" justifyContent="space-between" alignItems="center"
                  spacing={2}
                >
                  {data?.disciplinesAndStatusesId ? (
                    <Confirm title="Delete Discipline?"
                      content="Are you sure you want to delete discipline?"
                      onConfirm={() => deleteDiscipline(data.disciplinesAndStatusesId)}>
                      <Button
                        variant="contained"
                        color="warning"
                        endIcon={<DeleteIcon />}>Delete</Button>
                    </Confirm>) : <div />}
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => saveNewDiscipline(data)}
                    endIcon={<SaveIcon />}>Save</Button>
                </Stack>
              </Stack>
            </Box>
          </Panel>
        </Box>
      </Drawer>
    )
  );
}
