import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
// import { Bar, Line, Pie } from "react-chartjs-2"; might still use - lighter library

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const Header = styled.div`
  text-align: center;
  color: #238dcf;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Row = styled.div`
  flex: 1;
  margin: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  margin: 0px 35px;

  min-width: ${(props) => (props.big ? "600px" : "260px")};
  height: ${(props) => (props.big ? "600px" : "260px")};

  display: flex;
  flex-direction: column;
  align-items: center;

  // for flip
  background-color: transparent;
  perspective: 1000px;
`;

const PieContainer = styled.div`
  margin: 10px 35px;

  min-width: 600px;
  height: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const colors = {
  active: "#7ebb7e",
  pending: "#fb777d",
  therapists: "#3da9d2",
};
const Total = styled.div`
  font-weight: bold;
  font-size: 6em;
  color: ${(props) => colors[props.type]};
`;

const CardTitle = styled.div`
  font-size: 2em;
  font-weight: bold;
  align-self: center;
  color: grey;
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  ${Card}:hover & {
    transform: rotateY(180deg);
  }
`;

const Face = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  margin: 0px 10px;
`;

const Front = styled(Face)`
  background-color: #f3f3f3;
`;

const Back = styled(Face)`
  transform: rotateY(180deg);
  background-color: #f3f3f3;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 1.2em;
`;

const BackText = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  color: gray;
`;

export const Dashboard = () => {
  const [therapists, setTherapists] = useState([]);
  const [therapistsByDiscipline, setTherapistsByDiscipline] = useState({});
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [patientsByDiscipline, setPatientsByDiscipline] = useState({});
  const [pieChartData, setPieChartData] = useState(null);
  const [totalPending, setTotalPending] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [patientTotals, setPatientTotals] = useState({});

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getPatients = async () => {
      const token = await getAccessTokenSilently();

      try {
        setAccessToken(token);
        setLoading(true);



        const response = await axios.get(`${apiOrigin}/api/DashboardPatientTotals`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPatientTotals(response.data);


        // parsedPatients.forEach((patient) => {
        //   const disciplinesAndStatuses = patient.disciplinesAndStatuses;

        //   disciplinesAndStatuses.forEach((discipline) => {
        //     const type = discipline.Type;
        //     const status = discipline.Status;

        //     if (type && (status === "PENDING" || status === "ACTIVE")) {
        //       setupPatientsByDiscipline[type][status]++;
        //     }
        //   });
        // });

        // const totalPending =
        //   setupPatientsByDiscipline.PT.PENDING +
        //   setupPatientsByDiscipline.OT.PENDING +
        //   setupPatientsByDiscipline.SLP.PENDING;
        // const totalActive =
        //   setupPatientsByDiscipline.PT.ACTIVE +
        //   setupPatientsByDiscipline.OT.ACTIVE +
        //   setupPatientsByDiscipline.SLP.ACTIVE;

        // setPatientsByDiscipline(setupPatientsByDiscipline);

        // setTotalPending(totalPending);
        // setTotalActive(totalActive);

        // const referralSources = parsedPatients.reduce((acc, curr) => {
        //   if (curr.referralSource) {
        //     if (acc[curr.referralSource]) {
        //       acc[curr.referralSource]++;
        //     } else {
        //       acc[curr.referralSource] = 1;
        //     }
        //   }
        //   return acc;
        // }, {});

        // const combineOther = {};

        // Object.keys(referralSources).forEach((source) => {
        //   const number = referralSources[source];
        //   if (number > 4) {
        //     combineOther[source] = number;
        //   } else {
        //     combineOther.Other = combineOther.Other
        //       ? combineOther.Other + number
        //       : number;
        //   }
        // });

        const chartData = response.data.refferalSourcesTotals.map((source) => {
          return {
            id: source.key,
            label: source.key,
            value: source.count,
          };
        });

        setPieChartData(chartData);

        setLoading(false);
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    };

    getPatients();
  }, []);

  useEffect(() => {
    const getTherapists = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);

        const response = await fetch(`${apiOrigin}/api/therapists`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const allTherapistData = await response.json();
        setTherapists(allTherapistData);

        const therapistsByDisciplineSetup = allTherapistData.reduce(
          (acc, curr) => {
            if (curr.discipline) {
              acc[curr.discipline.trim()]++;
            }

            return acc;
          },
          {
            PT: 0,
            OT: 0,
            SLP: 0,
          }
        );

        setTherapistsByDiscipline(therapistsByDisciplineSetup);

        setLoading(false);
      } catch (error) {
        alert(`Error: ${error}`);
      }
    };

    getTherapists();
  }, []);

  return (
    <>
      <Header>
        <h1>Dashboard</h1>
      </Header>

      <DashboardContainer>
        <Row>
          <Card>
            <FlipCardInner>
              <Front>
                <Total type="therapists">{therapists.length}</Total>
                <CardTitle>Therapists</CardTitle>
              </Front>

              <Back>
                <BackText>Therapists Breakdown</BackText>
                {Object.keys(therapistsByDiscipline).map((discipline) => {
                  const count = therapistsByDiscipline[discipline];

                  return (
                    <div key={discipline}>{`${discipline}: ${count}`}</div>
                  );
                })}
              </Back>
            </FlipCardInner>
          </Card>

          <Card>
            <FlipCardInner>
              <Front>
                <Total type="pending">{patientTotals.totalDisciplinePending}</Total>
                <CardTitle>Pending</CardTitle>
              </Front>

              <Back>
                <BackText>Pending Breakdown</BackText>
                {patientTotals.pt && (
                  <>
                    <div>{`PT: ${patientTotals.pt.pending}`}</div>
                    <div>{`OT: ${patientTotals.ot.pending}`}</div>
                    <div>{`SLP: ${patientTotals.slp.pending}`}</div>
                  </>
                )}
              </Back>
            </FlipCardInner>
          </Card>

          <Card>
            <FlipCardInner>
              <Front>
                <Total type="active">{patientTotals.totalPatients}</Total>
                <CardTitle>Patients</CardTitle>
              </Front>

              <Back>
                <BackText>Active Breakdown</BackText>
                {patientTotals.pt && (
                  <>
                    <div>{`PT: ${patientTotals.pt.active}`}</div>
                    <div>{`OT: ${patientTotals.ot.active}`}</div>
                    <div>{`SLP: ${patientTotals.slp.active}`}</div>
                  </>
                )}
              </Back>
            </FlipCardInner>
          </Card>
        </Row>

        <Row>
          <PieContainer>
            {pieChartData && (
              <ResponsivePie
                data={pieChartData}
                sortByValue
                margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.4} // radius of inside donut hole (ratio of full radiu)
                // by default has radial labels
                // all numbers are px
                radialLabelsTextXOffset={4} // how far text is from line
                radialLabelsTextColor="#333333" // color of radial label text
                radialLabelsLinkDiagonalLength={12} // how far the diagonal line extends
                radialLabelsLinkHorizontalLength={12} // how far the horizontal line extends
                radialLabelsLinkStrokeWidth={1} // width of lines
                radialLabelsLinkColor={{ from: "color" }} // color of the line, inherits from the pie slice
                radialLabelsSkipAngle={10}
                // by default has slice labels
                slicesLabelsTextColor="#333333" // color of slice label text
              />
            )}
            <CardTitle>Referral Sources</CardTitle>
          </PieContainer>
        </Row>
      </DashboardContainer>
    </>
  );
};

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <Loading />,
});
