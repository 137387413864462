import { useSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';

export default function useAxiosHelpers() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleErrorFunctions = {
        handleError(err) {
            enqueueSnackbar(errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
        }
    }

    return handleErrorFunctions;
}
const createAction = closeSnackbar => key => (
    <SnackbarNotificationsClose
      onClick={() => {
        closeSnackbar(key)
      }}
    />
  );

export const getErrorSnackbarOptions = closeSnackbar => ({
    variant: "error",
    autoHideDuration: 5000,
    action: createAction(closeSnackbar)
  });

const errorDescription = error => {
    if (error.response && error.response.data) {
        if (typeof error.response.data === "object" && error.response.data.title) {
            return error.response.data.title;
        } else if (typeof error.response.data && error.response.data.message) {
            return error.response.data.message;
        } else if (error.response && error.response.status === 551) {
            return "Please contact customer support to access these reports.";
        }
        if (typeof error.response.data === "object") {
            return JSON.stringify(error.response.data);
        }
        return error.response.data;
    } else {
        return error.message;
    }
};

function SnackbarNotificationsClose(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick}>
        <CloseIcon />
      </div>
    );
  }