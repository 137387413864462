import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";
import Loading from "../components/Loading";
import DisciplinesAndStatusesSelect from "../components/DisciplineTypesSelect";
import DisciplineTypesStatusSelect from "../components/DisciplineTypesStatusSelect";
import AddressAutocomplete from "../components/AddressAutocomplete";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import CSVReader from 'react-csv-reader'

import "../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;


export const PendingPatients = ({ therapists, directorsAndZipcodes }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [disciplinesAndStatuses, setDisciplinesAndStatuses] = useState([]);
  const [directorId, setDirectorId] = useState("");
  const [dob, setDOB] = useState("");
  const [ssn, setSSN] = useState("");
  const [notes, setNotes] = useState("");
  const [primaryName, setPrimaryName] = useState("");
  const [primaryId, setPrimaryId] = useState("");
  const [secondaryName, setSecondaryName] = useState("");
  const [secondaryId, setSecondaryId] = useState("");
  const [doctor, setDoctor] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [doctorFax, setDoctorFax] = useState("");
  const [partBDeductible, setPartBDeductible] = useState("");
  const [partBUsedOT, setPartBUsedOT] = useState("");
  const [partBUserPT_SLP, setPartBUserPT_SLP] = useState("");
  const [referralSource, setReferralSource] = useState("");
  const [smsExtendedText, setSMSExtendedText] = useState("");
  const [directorAutoSelected, setDirectorAutoSelected] = useState(false)
  const [isFacility, setIsFacility] = useState(false);

  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [modal, setModal] = useState(false);
  const [CSVdata, setCSVdata] = useState([]);

  const [expandedColumns, setExpandedColumns] = useState(false);

  const [directorSort, setDirectorSort] = useState("");

  const { user, getAccessTokenSilently } = useAuth0();

  const toggle = async () => {
    setFirstName("");
    setLastName("");
    setAddress1("");
    setFullAddress("");
    setAddress2("");
    setCity("");
    setState("");
    setZip("");
    setPhone("");
    setDirectorId("");
    setDirectorAutoSelected(false);
    setDisciplinesAndStatuses([]);
    setDOB("");
    setSSN("");
    setNotes("");
    setPrimaryId("");
    setPrimaryName("");
    setSecondaryId("");
    setSecondaryName("");
    setDoctor("");
    setDoctorPhone("");
    setDoctorFax("");
    setPartBDeductible("");
    setPartBUsedOT("");
    setPartBUserPT_SLP("");
    setReferralSource("");
    setSMSExtendedText("");
    setIsFacility(false);

    setModal(!modal);
  }

  useEffect(() => {
    const getPatients = async () => {
      const token = await getAccessTokenSilently();
      try {
        setAccessToken(token);

        const response = await fetch(`${apiOrigin}/api/patients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();
        //console.log(responseData);
        setPatients(
          responseData.map((x) => {
            const patient = x;
            // patient.disciplinesAndStatuses = JSON.parse(
            //   x.disciplinesAndStatuses
            // );
            return patient;
          })
        );
        setLoading(false)
      } catch (error) {
        alert("error: " + error);
      }
    };

    getPatients();
  }, []);

  const addPatient = async () => {
    const needsAddress = !isFacility && !fullAddress;
    if (!firstName || !lastName || needsAddress || !phone || disciplinesAndStatuses.length === 0 || !dob || !primaryId || !primaryName || !referralSource || !directorId) {
      alert('Missing one (or more) of the required fields: First Name, Last Name, Address (if is not facility), Phone, Discipline, DOB, Primary ins. Id and Name, Referral Source, Director')
      return;
    }

    if(patients.filter(x => x.fullAddress == fullAddress).length > 0){
      alert('Patient with same address already exists');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${apiOrigin}/api/patients`, {
        method: "POST",
        body: JSON.stringify({
          firstName,
          lastName,
          fullAddress,
          address1,
          address2,
          city,
          state,
          zip,
          phone,
          disciplinesAndStatuses: JSON.stringify(disciplinesAndStatuses),
          directorId,
          dob,
          ssn,
          notes,
          primaryName,
          primaryId,
          secondaryName,
          secondaryId,
          doctor,
          doctorPhone,
          doctorFax,
          partBDeductible,
          partBUsedOT,
          partBUserPT_SLP,
          referralSource,
          smsExtendedText,
          isFacility
        }),
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });

      const responseData = await response.json();
      responseData.disciplinesAndStatuses = JSON.parse(responseData.disciplinesAndStatuses);
      setPatients([...patients, responseData]);
    } catch (error) {
      alert("error: " + error);
    }

    setLoading(false);
    toggle();
  };

  const addBatch = async () => {
    setLoading(true);
    
    try {
      const response = await fetch(`${apiOrigin}/api/patients/batch`, {
        method: "POST",
        body: JSON.stringify(CSVdata),
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });

      const responseData = await response.json();

      if (response.status == 400) {
        alert(responseData.msg);
        return;
      }

      setPatients([...patients, ...responseData]);
      alert('Data was added!');
    } catch (error) {
      alert("error: " + error);
    }

    setLoading(false);
    setCSVdata([]);
  };

  const setDisciplinesAndStatusesValue = (value) => {
    const disciplines = disciplinesAndStatuses.filter((d) => d.Type === value).length === 1
      ? disciplinesAndStatuses.filter((x) => x.Type !== value)
      : [...disciplinesAndStatuses, { Type: value, Status: "PENDING", Date: new Date().toISOString() }];

    setDisciplinesAndStatuses(disciplines);
  };

  const setAutoCompleteAddress = (fullAddress, address1, city, state, zip) => {
    setFullAddress(fullAddress);
    setAddress1(address1);
    setCity(city);
    setState(state);
    setZip(zip);

    const directorOpt = directorsAndZipcodes.filter(x => x.zipCodes.includes(zip));
    if (directorOpt.length > 0) {
      const director = directorOpt[0];
      setDirectorId(director.rowKey);
      setDirectorAutoSelected(true);
    }
  }

  const getFullName = (therapist) => therapist ? therapist.lastName + " " + therapist.firstName : "";

  const DisciplinesAndStatusesFormatter = (cell, row) => {
    //console.log("the cell", cell);
    if (cell) {
      return (
        <span>
          {cell.map((x, i) => (
            <p key={i}>
              <strong
                key={i}
                style={{
                  marginLeft: "15px",
                  color: x.Status === "PENDING" ? "#d23d3d" : (x.Status === "ACTIVE" ? "#50a250" : (x.Status === "DISCHARGED" ? "#484646" : "#9a9a9a")),
                }}
              >
                {x.Type} &#x25E6; {x.Status} &#x25E6; {new Date(x.Date).toLocaleString().split(',')[0]}{x.TherapistId && <span> &#x25E6; </span>} {getFullName(therapists.filter(t => t.rowKey === x.TherapistId)[0])}
              </strong>
            </p>
          ))}
        </span>
      );
    }
    return <span></span>;
  };

  const columns = [
    {
      dataField: "rowKey",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "directorId",
      text: "Director",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "225px" };
      },
      formatter: (cell, row) => <span>{cell && row.isDirector !== "true" ? getFullName(therapists.filter(x => x.rowKey === cell)[0]) : ""}</span>,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <Input
            type="select"
            name="directorsSelet"
            id="directorsSelet"
            defaultValue={value ? value : ""}
            onChange={(e) => editorProps.onUpdate(e.target.value)}
          >
            <option value="">Select Director</option>
            {therapists.filter(x => x.isDirector === "true").map((x, i) => <option key={i} value={x.rowKey}>{getFullName(x)}</option>)}
          </Input>
        );
      }
    },
    {
      dataField: "isFacility",
      text: "Is Facility",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      },
      formatter: (cell, row) => <FormGroup check><Input readOnly type="checkbox" checked={cell === "true"} /></FormGroup>,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        //console.log(value);
        return (
          <FormGroup check><Input type="checkbox" checked={value === "true"} 
          onChange={(e) => {
              let checked = e.target.checked;
              // const message = "By changing " + (checked ? "to" : "from") + " facility patient, this patient will be " + 
              //   (checked ? "excluded" : "included") + "from the daily pairing process and from the bi-weekly discharged process. Continue?";
              //checked = window.confirm(message) ? checked : !checked;
              editorProps.onUpdate(checked.toString())
            }
          } /></FormGroup>
        );
      },
      csvText: 'isFacility'
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "180px" };
      },
    },
    {
      dataField: "fullAddress",
      text: "Full Address",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "320px" };
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const setAutoCompleteAddress = (fullAddress, address1, city, state, zip) => {
          setAddress1(address1);
          setCity(city);
          setState(state);
          setZip(zip);
          editorProps.onUpdate(fullAddress);
        }
        return (
          <AddressAutocomplete setAddress={setAutoCompleteAddress} value={value} />
        );
      },
      sortValue: (cell, row) => cell ? cell.split(',')[1].trim() : ""
    },
    {
      dataField: "disciplinesAndStatuses",
      text: "Discipline Types",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "450px" };
      },
      formatter: DisciplinesAndStatusesFormatter,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => <DisciplineTypesStatusSelect fullUpdate={editorProps.onUpdate} value={value} getFullName={getFullName} therapists={therapists} />
      ,
    },
    {
      dataField: "createdDate",
      text: "Created Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      formatter: (cell) => {
        if (!cell) return;
        let dateObj = new Date(cell);
        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "address1",
      text: "Address1",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "320px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "address2",
      text: "Address2",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "zip",
      text: "Zip",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      editable: false,
      hidden: !expandedColumns
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "dob",
      text: "DOB",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      formatter: (cell) => {
        if (!cell) return;
        let dateObj = new Date(cell);
        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editor: {
        type: Type.DATE
      },
      hidden: !expandedColumns
    },
    {
      dataField: "ssn",
      text: "SSN",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "500px" };
      },
      editor: {
        type: Type.TEXTAREA
      },
      hidden: !expandedColumns
    },
    {
      dataField: "primaryName",
      text: "Primary Ins. Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "primaryId",
      text: "Primary Ins. Id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "secondaryName",
      text: "Secondary Ins. Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "secondaryId",
      text: "Secondary Ins. Id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "doctor",
      text: "Doctor",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "doctorPhone",
      text: "Doctor Phone",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "170px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "doctorFax",
      text: "Doctor Fax",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "partBDeductible",
      text: "Part B Deductible",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "partBUsedOT",
      text: "Part B User OT",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "180px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "partBUserPT_SLP",
      text: "Part B User PT/SLP",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "referralSource",
      text: "Referral Source",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns
    },
    {
      dataField: "smsExtendedText",
      text: "SMS Extended Text",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
      hidden: !expandedColumns,
      validator: (newValue, row, column) => {
        if(newValue.length > 15) {
          return {
            valid: false,
            //message: "max length is 15 characters"
          };
        }
        return true;
      }
    },
  ];

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    if (oldValue === newValue) return false;
    setLoading(true);

    if (column.dataField === "fullAddress") {
      row.address1 = address1;
      row.city = city;
      row.state = state;
      row.zip = zip;
    }

    row[column.dataField] = newValue;
    const updatedPatient = { ...row };

    updatedPatient.disciplinesAndStatuses = JSON.stringify(
      updatedPatient.disciplinesAndStatuses
    );

    fetch(`${apiOrigin}/api/patients/${row.rowKey}`, {
      method: "PUT",
      body: JSON.stringify(updatedPatient),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        done(true);
        setLoading(false);
      })
      .catch((error) => {
        alert("error: " + error);

        done(false);
        setLoading(false);
      });

    setLoading(false);

    return { async: true };
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
      header
        .toLowerCase()
        .replace(/\W/g, '_')
  }

  const fileLoad = (data, fileInfo) => {
    setCSVdata(data);
  }

  const { SearchBar } = Search;

  return (
    <>
      {/* <Header><h1>Pending Patients</h1></Header> */}

      <ToolkitProvider
        keyField="rowKey"
        data={patients.filter(p => (directorSort === "" || p.directorId == directorSort) &&
          (p.disciplinesAndStatuses.length === 0 || p.disciplinesAndStatuses.filter(x => x.Status === "PENDING").length > 0))}
        // .map(x => { const n = x; n.disciplinesAndStatuses = x.disciplinesAndStatuses.filter(y => x.Status === "PENDING"); return n})
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <Button color="secondary" onClick={() => setExpandedColumns(!expandedColumns)}>
                    {expandedColumns ? "Condense" : "Expand"}
                  </Button>{" "}
                  <Button color="primary" onClick={toggle}>
                    Add +
                  </Button>

                  {(user.email == "nachfriedman@gmail.com") && <CSVReader
                    cssClass="csv"
                    cssInputClass="csv_input"
                    label="Select csv file"
                    parserOptions={papaparseOptions}
                    onFileLoaded={(data, fileInfo) => fileLoad(data, fileInfo)}
                  />}
                  
                  {CSVdata.length > 0 &&
                    <Button color="primary" onClick={addBatch}>
                      Add Bulk
                    </Button>}
                </div>
                <div style={{ display: "flex" }}>
                  <SearchBar {...props.searchProps} />
                  <Input
                    type="select"
                    name="directorSort"
                    id="directorSort"
                    defaultValue={directorSort}
                    onChange={(e) => setDirectorSort(e.target.value)}
                    style={{ marginLeft: "30px" }}
                  >
                    <option value="">All Directors</option>
                    {directorsAndZipcodes.map((x, i) => <option key={i} value={x.rowKey}>{x.firstName + " " + x.lastName}</option>)}
                  </Input>
                </div>
              </div>
              <BootstrapTable
                keyField="rowKey"
                data={patients}
                columns={columns}
                striped
                hover
                condensed
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  beforeSaveCell,
                })}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>

      {loading && <Spinner animation="border" role="status" />}

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Add Patient</ModalHeader>

        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="firstName" sm={2}>
                First Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastName" sm={2}>
                Last Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="fullAddress" sm={2}>
                Full Address
              </Label>
              <Col sm={10}>
                <AddressAutocomplete setAddress={setAutoCompleteAddress} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="address1" sm={2}>
                Address1
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Address1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="address2" sm={2}>
                Address2
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Address2"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="city" sm={2}>
                City
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="state" sm={2}>
                State
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="zip" sm={2}>
                Zip
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="zip"
                  id="zip"
                  placeholder="Zip"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>
                Phone
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => { if (!isNaN(e.target.value) || e.target.value === "+") setPhone(e.target.value) }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="director" sm={2}>
                Director
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="director"
                  id="director"
                  defaultValue={directorId}
                  onChange={(e) => setDirectorId(e.target.value)}
                  //disabled={directorAutoSelected}
                >
                  <option value="">Select Director</option>
                  {directorsAndZipcodes.map((x, i) => <option selected={x.rowKey == directorId} key={i} value={x.rowKey}>{x.firstName + " " + x.lastName}</option>)}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup className="disciplinesAndStatuses">
              <Label for="disciplinesAndStatuses">Disciplines</Label>
              <DisciplinesAndStatusesSelect
                selected={disciplinesAndStatuses}
                setType={setDisciplinesAndStatusesValue}
              />
            </FormGroup>
            <FormGroup row>
              <Label for="dob" sm={2}>
                DOB
              </Label>
              <Col sm={10}>
                <Input
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder="DOB"
                  value={dob}
                  onChange={(e) => setDOB(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="ssn" sm={2}>
                SSN
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="ssn"
                  id="ssn"
                  placeholder="SSN"
                  value={ssn}
                  onChange={(e) => setSSN(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={10}>
                <CustomInput
                  type="checkbox"
                  id="isFacility"
                  name="isFacility"
                  label="Is Facility"
                  onChange={(e) => {
                    let checked = e.target.checked;
                    // const message = "By changing " + (checked ? "to" : "from") + " facility patient, this patient will be " + 
                    //     (checked ? "excluded" : "included") + "from the daily pairing process and from the bi-weekly discharged process. Continue?";
                      //checked = window.confirm(message) ? checked : !checked;
                      setIsFacility(checked)
                    }
                  }
                  checked={isFacility}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="notes" sm={2}>
                Notes
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="primaryName" sm={2}>
                Primary Ins. Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="primaryName"
                  id="primaryName"
                  placeholder="Primary Ins. Name"
                  value={primaryName}
                  onChange={(e) => setPrimaryName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="primaryId" sm={2}>
                Primary Ins. Id
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="primaryId"
                  id="primaryId"
                  placeholder="Primary Ins. Id"
                  value={primaryId}
                  onChange={(e) => setPrimaryId(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="secondaryName" sm={2}>
                Secondary Ins. Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="secondaryName"
                  id="secondaryName"
                  placeholder="Secondary Ins. Name"
                  value={secondaryName}
                  onChange={(e) => setSecondaryName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="secondaryId" sm={2}>
                Secondary Ins. Id
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="secondaryId"
                  id="secondaryId"
                  placeholder="Secondary Ins. Id"
                  value={secondaryId}
                  onChange={(e) => setSecondaryId(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="doctor" sm={2}>
                Doctor
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="doctor"
                  id="doctor"
                  placeholder="Doctor"
                  value={doctor}
                  onChange={(e) => setDoctor(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="doctorPhone" sm={2}>
                Doctor Phone
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="doctorPhone"
                  id="doctorPhone"
                  placeholder="Doctor Phone"
                  value={doctorPhone}
                  onChange={(e) => setDoctorPhone(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="doctorFax" sm={2}>
                Doctor Fax
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="doctorFax"
                  id="Doctor Fax"
                  placeholder="doctorFax"
                  value={doctorFax}
                  onChange={(e) => setDoctorFax(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="partBDeductible" sm={2}>
                Part B Deductible
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="partBDeductible"
                  id="partBDeductible"
                  placeholder="Part B Deductible"
                  value={partBDeductible}
                  onChange={(e) => setPartBDeductible(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="partBUsedOT" sm={2}>
                Part B Used OT
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="partBUsedOT"
                  id="partBUsedOT"
                  placeholder="Part B Used OT"
                  value={partBUsedOT}
                  onChange={(e) => setPartBUsedOT(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="partBUserPT_SLP" sm={2}>
                Part B User PT/SLP
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="partBUserPT_SLP"
                  id="partBUserPT_SLP"
                  placeholder="Part B User PT/SLP"
                  value={partBUserPT_SLP}
                  onChange={(e) => setPartBUserPT_SLP(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="referralSource" sm={2}>
                Referral Source
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="referralSource"
                  id="referralSource"
                  placeholder="Referral Source"
                  value={referralSource}
                  onChange={(e) => setReferralSource(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="smsExtendedText" sm={2}>
              SMS Extended Text
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="smsExtendedText"
                  id="smsExtendedText"
                  placeholder="SMS Extended Text"
                  value={smsExtendedText}
                  onChange={(e) => {
                    if(e.target.value.length > 15) {
                      alert("max length is 15 characters")
                      return;
                    }
                    setSMSExtendedText(e.target.value)}
                  }
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={addPatient}>
            Add
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withAuthenticationRequired(PendingPatients, {
  onRedirecting: () => <Loading />,
});
