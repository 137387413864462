import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DateBox } from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import DisciplineDialog from "./DisciplineDialog/DisciplineDialog";
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import DisciplineSimpleGrid from "./DisciplineSimpleGrid";
import { makeStyles } from '@material-ui/core/styles';

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  inline: {
    display: 'inline',
    wordWrap: "break-word"
  },
}));

export default function PatientDetails({ patientsId, therapistsData }) {
  const classes = useStyles();
  const [patientNotes, setPatientNotes] = useState([]);
  const [showNotes, setShowNotes] = useState();
  const [newNote, setNewNote] = useState({});
  const [loading, setLoading] = useState(false);
  const [followUp, setFollowUp] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [newDisciplineDialogOpen, setNewDisciplineDialogOpen] = useState(false);
  const [disciplineToEdit, setDisciplineToEdit] = useState(null);
  const [patientId, setPatientId] = useState(patientsId);
  const [userInfo, setUserInfo] = useState();
  const [updateFollowUpDialog, setUpdateFollowUpDialog] = useState(false);
  const [updateFollowUpDialogDate, setUpdateFollowUpDialogDate] = useState(new Date());
  let isLoadingNotes = false;
  let fetchedFollowUpRef = useRef(null);

  useEffect(() => {
    setPatientId(patientsId);
    if (patientsId) {
      setShowNotes(true);
      getNotes();
    }
  }, [patientsId]);

  const getNotes = async () => {
    try {
      isLoadingNotes = true;
      const response = await fetch(
        `${apiOrigin}/api/PatientNotes/${patientsId}`,
        {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, },
        }
      );

      const responseData = await response.json();
      setPatientNotes(responseData.notes);
      let dt = responseData.followUp ? new Date(responseData.followUp) : null;
      fetchedFollowUpRef.current = dt;
      setFollowUp(dt);
      setUserInfo(responseData.userInfo);
    } catch (error) {
      alert("error: " + error);
    } finally {
      isLoadingNotes = false;
    }
  };

  async function saveNote() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      if (
        newNote?.note == "" ||
        newNote?.note == undefined ||
        newNote?.note == null
      ) {
        alert("Please enter a message");
        return;
      }
      let noteInfo = {
        Note: newNote.note,
        patiendId: patientId,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      if (patientId) {
        let data = await axios.post(
          `${apiOrigin}/api/SavePatientNote/${patientId}`,
          noteInfo,
          config
        );
        setNewNote({ note: '' });
        getNotes();
        setUpdateFollowUpDialogDate(followUp);
        setUpdateFollowUpDialog(true);
      }
    } catch (ex) {
      console.log(ex);
      alert(ex);
    } finally {
      setLoading(false);
    }
  }

  function parseDate(addedOn) {
    let fmt = new Intl.DateTimeFormat("en-US", {
      dateStyle: "short",
      timeStyle: "short",
      timeZone: "est",
      hour12: true,
    });
    if (!addedOn) {
      return '';
    }
    let parsedDate = fmt.format((new Date(addedOn)));
    parsedDate = parsedDate.replace(/ (..)$| at\b/g, "$1");
    return parsedDate;
  }

  const updateFollowUp = async (value) => {
    if (isLoadingNotes || value == fetchedFollowUpRef.current) {
      console.log("follow up was not updated");
      return;
    }
    const token = await getAccessTokenSilently();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.post(
      `${apiOrigin}/api/UpdatePatientFollowUp/${patientId}`,
      { followUp: value },
      config
    );
    setFollowUp(value);
    fetchedFollowUpRef.current = null;
  };

  function updateNote(e) {
    setNewNote({ note: e.target.value });
  }

  function getInitials(name) {
    let initials = '';
    if (name) {
      let splits = name.split(' ');
      initials = splits[0].charAt(0);
      if (splits.length > 1) {
        initials += splits[splits.length - 1].charAt(0);
      }
    }

    return initials;
  }

  return (
    <>
      <Box display='flex' flexDirection='column' flex={1} height='calc(100vh - 175px)'>
        <Box display='flex' justifyContent='space-between' >
          <DateBox
            text="Follow Up"
            label="Follow Up"
            height="35px"
            width="200px"
            value={followUp}
            showClearButton
            onValueChanged={(e) => updateFollowUp(e.value)}
          />
          {patientId && (
            <Button
              icon="plus"
              onClick={() => {
                setDisciplineToEdit(null);
                setNewDisciplineDialogOpen(true);
              }}
            />
          )}
        </Box>

        <Box my={1} maxHeight='30%' minHeight={125}>
          {patientId && (
            <DisciplineSimpleGrid
              patientsId={patientId}
              isBasic
              editDiscipline={(data) => {
                setDisciplineToEdit(data);
                setNewDisciplineDialogOpen(true);
              }}
            />
          )}
        </Box>
        <Divider />
        <List className={classes.root}>
          {patientNotes && patientNotes.map(note => (
            <>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>{note.addedBy != null ? getInitials(note.addedBy) : "U"}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography variant="caption" color="GrayText">
                        {note.addedBy != null ? note.addedBy : "by Unknown"}
                      </Typography>
                      <Typography variant="caption" color="GrayText">
                        {parseDate(note.addedOn)}
                      </Typography>
                    </Box>
                  </>}
                  secondary={<>
                    <Typography
                      className={classes.inline}
                      variant="body2"
                      color="textPrimary">
                      {note.note}
                    </Typography>
                  </>}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
        </List>

        <Box display='flex' my={1}>
          <TextField
            value={newNote.note}
            label="new note"
            onChange={updateNote}
            size="small" fullWidth
          />
          <IconButton onClick={saveNote} color="primary">
            <SendIcon />
          </IconButton>
        </Box>
        <Box mb={2}>
          <Typography variant="caption" gutterBottom>
            Added {userInfo?.addedByUser ? `By: ${userInfo.addedByUser}` : ""} On: {parseDate(userInfo?.createdDate)}
          </Typography>
          {userInfo?.updatedOn &&
            <Typography variant="caption" gutterBottom display='block'>
              Modified {userInfo.updatedByUser ? `By: ${userInfo.updatedByUser}` : ""} On: {parseDate(userInfo.updatedOn)}
            </Typography>}
        </Box>
      </Box>

      <Dialog open={updateFollowUpDialog} onClose={(e) => setUpdateFollowUpDialog(false)}>
        <DialogTitle >Update Follow Up?</DialogTitle>
        <DialogContent>
          <Box p={2}>
            <DateBox
              text="Follow Up"
              label="Follow Up"
              height="35px"
              width="200px"
              value={updateFollowUpDialogDate}
              showClearButton
              onValueChanged={(e) => setUpdateFollowUpDialogDate(e.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setUpdateFollowUpDialog(false)}>Cancel</Button>
          <Button onClick={(e) => {
            updateFollowUp(updateFollowUpDialogDate)
            setUpdateFollowUpDialog(false)
          }}>Save</Button>
        </DialogActions>
      </Dialog>

      <DisciplineDialog
        isOpen={newDisciplineDialogOpen}
        patientsId={patientId}
        therapistData={therapistsData}
        closePopup={() => {
          setNewDisciplineDialogOpen(false);
        }}
        onSuccess={() => {
          let p = patientId;
          setPatientId(null);
          setPatientId(p);
        }}
        initialData={disciplineToEdit}
      />
    </>
  );
}
