import React from "react";
import DisciplinesGrid from "../components/DisciplinesGrid";

export const Disciplines = ({ therapistsData }) => {
  return (
    <>
      <DisciplinesGrid />
    </>
  );
};

export default Disciplines;
