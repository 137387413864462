import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import "devextreme-react/text-area";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import PatientDetails from "../../components/PatientDetails";
import DisciplinesGrid from "../../components/DisciplinesGrid";
import DisciplineDialog from "../../components/DisciplineDialog/DisciplineDialog";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";
import PatientEdit from "./PatientEdit";
import { Paper, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;
/* global google */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      height='90%'
    >
      {children}
    </Box>
  );
}

const useStyles = makeStyles({
  paper: {
    width: "100%",
    height: "100%"
  },
  card: {
    backgroundColor: 'blue'
  }
});

export default function PatientEditPage({ directorsAndZipcodes, therapistsData }) {
  const [patient, setPatient] = useState({});
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disciplineToEdit, setDisciplineToEdit] = useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const classes = useStyles();

  function RenderNotes(props) {
    return (
      <Paper elevation={3} className={classes.paper}>
        <Box m={1}>
          <PatientDetails
            patientsId={patient?.patientId}
            therapistsData={therapistsData}
          />
        </Box>
      </Paper>
    )
  }

  function onSaveCallBack(patient) {
    if (!parsed.patientId) {
      history.push("/patient/new?patientId=" + patient.rowKey);
    } else if (patient === "deleted") {
      history.push("/patientsList");
    }
  }


  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <h5 className="page--Headline">
          <span className="color-primary">
            {patient?.patientId ? "Edit" : "New"} Patient
          </span>
          {patient && (
            <span>
              {patient.firstName} {patient.lastName}
            </span>
          )}
        </h5>
        <Tabs value={tabValue} onChange={(e, nv) => setTabValue(nv)} height='100%'>
          <Tab label="General" />
          <Tab label="Disciplines" disabled={!patient?.patientId} />
        </Tabs>
        <Box display='flex' height='calc(100vh - 175px)' width='100%' flexDirection='row-reverse'>

          <Box display='flex' flexDirection='column' width='100%' mx={1} height='100%' flex={1} minWidth={400}>
            {patient?.patientId && (
              <RenderNotes showNotes={patient?.patientId} />
            )}
          </Box>

          <Box m={1} height='100%' flex={3} width='75%'>
            <TabPanel value={tabValue} index={0}>
              <PatientEdit patientId={parsed.patientId}
                directorsAndZipcodes={directorsAndZipcodes}
                showSaveAndClose={false}
                onSaveCallBack={onSaveCallBack}
                setPatientCallBack={(p) => setPatient(p)}
              //onClose={() => setPreviewPatient(null)}
              //reloadData={triggerReloadData}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <>
                <Button icon="plus" onClick={() => setDialogOpen(true)} />
                <DisciplinesGrid
                  patientsId={patient?.patientId}
                  editDiscipline={(data) => {
                    setDisciplineToEdit(data);
                    setDialogOpen(true);
                  }}
                />
              </>
            </TabPanel>
          </Box>
        </Box>
      </div>

      {dialogOpen && (
        <DisciplineDialog
          therapistData={therapistsData}
          patientsId={patient?.patientId}
          isOpen={dialogOpen}
          closePopup={() => {
            setDisciplineToEdit(null);
            setDialogOpen(false);
          }}
          initialData={disciplineToEdit}
        />
      )}
    </>
  );
}
