import React, { useState, useEffect } from "react";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Button,
    RemoteOperations,
    HeaderFilter,
    ColumnChooser,
    Sorting,
    ColumnFixing,
    FilterPanel,
    FilterBuilderPopup,
    Export,
    Toolbar,
    Item,
    Editing
} from "devextreme-react/data-grid";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { createStore } from "devextreme-aspnet-data-nojquery";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const serviceUrl = `${apiOrigin}/api`;
export default function ReferralSourcesDxGrid(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [remoteDataSource, setRemoteDataSource] = useState();

    useEffect(() => {
        const getReferralSources = async () => {
            const token = await getAccessTokenSilently();
            setRemoteDataSource(createStore({
                key: "id",
                loadUrl: serviceUrl + "/ReferralSources",
                insertUrl: `${serviceUrl}/ReferralSources`,
                updateUrl: `${serviceUrl}/ReferralSources`,
                deleteUrl: `${serviceUrl}/ReferralSources`,
                onBeforeSend: (e, s) => {
                    s.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                },
            }));
        };
        getReferralSources();
    }, []);


    return (
        <>{
            remoteDataSource ?
                <DataGrid
                    remoteOperations
                    dataSource={remoteDataSource}
                    height="100%"
                    width="100%"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    columnResizingMode={"widget"}
                    showColumnLines={false}
                    rowAlternationEnabled
                    focusedRowEnabled
                    autoNavigateToFocusedRow
                >
                    < Editing
                        mode="row"
                        allowAdding={true}
                        allowDeleting={true}
                        allowUpdating={true}
                    />
                    <RemoteOperations groupPaging={true} />
                    <ColumnFixing enabled={true} />
                    <SearchPanel visible width={250} />
                    <HeaderFilter visible />
                    <ColumnChooser enabled />
                    <Sorting mode="multiple" />
                    <ColumnFixing />
                    <Paging defaultPageSize={40} />
                    <Pager showPageSizeSelector />
                    <FilterPanel visible />
                    <FilterBuilderPopup />
                    <Export enabled={true} />

                    <Column dataField="id" allowEditing={false}/>
                    <Column dataField="name" />
                </DataGrid >
                : <div></div>
        }</>
    );
};