import React, { useState, useContext } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PatientDrawerContext, UserProfileContext } from '../App'

import history from "../history";

const Logo = styled.img`
  height: 50px;
  margin-right: 50px;

  :hover {
    cursor: pointer;
  }
`;

const NAV_BAR_LINKS = [
  {
    path: "/",
    label: "Dashboard",
  },
  {
    path: "/patientsList",
    label: "Patient List",
  },
  {
    path: "/therapists",
    label: "Therapists",
  },
  {
    path: "/statusupdates",
    label: "Status Updates",
  },
  {
    path: "/disciplines",
    label: "Disciplines",
  },
];

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const { setPreviewPatient } = useContext(PatientDrawerContext);
  const userProfile = useContext(UserProfileContext);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <Navbar color="light" light expand="md" style={{ height: 60 }}>
      <Logo
        src='/logo.svg'
        alt="Logo"
        onClick={() => {
          if (window.location.pathname !== "/") history.push("/");
        }}
      />

      <NavbarToggler onClick={toggle} />

      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>

          {isAuthenticated &&
            NAV_BAR_LINKS.map(({ path, label }) => (
              <NavItem key={path}>
                <NavLink tag={RouterNavLink} to={path} exact>
                  {label}
                </NavLink>
              </NavItem>
            ))}
          {userProfile.allowManageUsers &&
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="profileDropDown">
                  Advanced
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/users' exact>Users</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/Directors' exact>Directors</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/ReferralSources' exact>Referral Sources</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/Facilities' exact>Facilities</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/Audit' exact>Audit</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/AuditReport' exact>Audit Report</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/logs' exact>logs</NavLink></NavItem>
                  </DropdownItem>

                  <DropdownItem id="qsLogoutBtn">
                    <NavItem><NavLink tag={RouterNavLink} to='/runPairing' exact>Run Pairing</NavLink></NavItem>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>}
        </Nav>

        <IconButton size="large" onClick={() => setPreviewPatient({})}>
          <AddCircleIcon />
        </IconButton >

        <Nav className="d-none d-md-block" navbar>
          {!isAuthenticated && (
            <NavItem>
              <Button
                id="qsLoginBtn"
                color="primary"
                className="btn-margin"
                onClick={() => loginWithRedirect()}
              >
                Log in
              </Button>
            </NavItem>
          )}
          {isAuthenticated && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret id="profileDropDown">
                <img
                  src={user.picture}
                  alt="Profile"
                  className="nav-user-profile rounded-circle"
                  width="42"
                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem header>{user.name}</DropdownItem>
                <DropdownItem
                  id="qsLogoutBtn"
                  onClick={() => logoutWithRedirect()}
                >
                  {/* <FontAwesomeIcon icon="power-off" className="mr-3" />  */}
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>

        {!isAuthenticated && (
          <Nav className="d-md-none" navbar>
            <NavItem>
              <Button
                id="qsLoginBtn"
                color="primary"
                block
                onClick={() => loginWithRedirect({})}
              >
                Log in
              </Button>
            </NavItem>
          </Nav>
        )}

        {isAuthenticated && (
          <Nav
            className="d-md-none justify-content-between"
            navbar
            style={{ minHeight: 170 }}
          >
            <NavItem>
              <span className="user-info">
                <img
                  src={user.picture}
                  alt="Profile"
                  className="nav-user-profile d-inline-block rounded-circle mr-3"
                  width="50"
                />
                <h6 className="d-inline-block">{user.name}</h6>
              </span>
            </NavItem>
            <NavItem>
              {/* <FontAwesomeIcon icon="user" className="mr-3" /> */}
              <RouterNavLink to="/profile">Profile</RouterNavLink>
            </NavItem>
            <NavItem>
              {/* <FontAwesomeIcon icon="power-off" className="mr-3" /> */}
              <RouterNavLink
                to="#"
                id="qsLogoutBtn"
                onClick={() => logoutWithRedirect()}
              >
                Log out
              </RouterNavLink>
            </NavItem>
          </Nav>
        )}
      </Collapse>
    </Navbar>
  );
};

export default NavBar;
