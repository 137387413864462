import React, { useState, useEffect, useRef } from "react";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    RemoteOperations,
    HeaderFilter,
    ColumnChooser,
    Sorting,
    ColumnFixing,
    FilterPanel,
    FilterBuilderPopup,
    Export,
    GroupPanel
} from "devextreme-react/data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import { createStore } from "devextreme-aspnet-data-nojquery";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const serviceUrl = `${apiOrigin}/api`;
export default function AuditReportDxGrid(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [remoteDataSource, setRemoteDataSource] = useState();
    const gridRef = useRef();

    useEffect(() => {
        const getReferralSources = async () => {
            const token = await getAccessTokenSilently();
            setRemoteDataSource(createStore({
                key: "uniqueuId",
                loadUrl: serviceUrl + "/AuditReport",
                onBeforeSend: (e, s) => {
                    s.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                },
            }));
        };
        getReferralSources();
    }, []);

    return (
        <>{
            remoteDataSource ?
                <DataGrid
                    ref={(ref) => { gridRef.current = ref; }}
                    remoteOperations
                    dataSource={remoteDataSource}
                    height="100%"
                    width="100%"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    columnResizingMode={"widget"}
                    showColumnLines={false}
                    rowAlternationEnabled
                    focusedRowEnabled
                    autoNavigateToFocusedRow
                >
                    <RemoteOperations groupPaging={true} />
                    <ColumnFixing enabled={true} />
                    <SearchPanel visible width={250} />
                    <HeaderFilter visible />
                    <ColumnChooser enabled />
                    <Sorting mode="multiple" />
                    <ColumnFixing />
                    <Paging defaultPageSize={40} />
                    <Pager showPageSizeSelector />
                    <FilterPanel visible />
                    <FilterBuilderPopup />
                    <Export enabled={true} />
                    <GroupPanel visible={true} />

                    <Column dataField="uniqueuId" visible={false} />
                    <Column dataField="displayName" />
                    <Column dataField="date" dataType="date" sortOrder="desc" format="shortDateShortTime" />
                    <Column dataField="patientsCreated" />
                    <Column dataField="disciplinesCreated" />
                    <Column dataField="disciplinesCreatedUniqueByPatient" />
                    <Column dataField="disciplineStatusUpdate" />
                    <Column dataField="disciplineStatusUpdateByPatient" />
                    <Column dataField="notesCreated" />
                    <Column dataField="notesCreatedByPatient" />
                </DataGrid >
                : <div></div>
        }</>
    );
};