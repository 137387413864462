import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Button,
  RemoteOperations,
  HeaderFilter,
  ColumnChooser,
  Sorting,
  ColumnFixing,
  FilterPanel,
  FilterBuilderPopup,
  Export,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { useWindowWidth } from "@react-hook/window-size";
import { useHistory } from "react-router-dom";
import PatientFilter from "./PatientFilter";
import { CheckBox } from "devextreme-react/check-box";

const disciplineCellRender = (data) => {
  if(data.data.disciplinesViews){
    return data.data.disciplinesViews.map(d => <div>
        {d.type} - {d.status} {d.therapist ? `(${d.therapist})` : ""}
    </div>);
  }
}

export default function PatientsDxGrid(props) {
  const [filter, setFilter] = useState();
  const history = useHistory();
  const onlyWidth = useWindowWidth();

  return (
    <DataGrid
      remoteOperations
      dataSource={props.remoteDataSource}
      height="100%"
      width="100%"
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      showBorders={true}
      columnResizingMode={"widget"}
      showColumnLines={false}
      rowAlternationEnabled
      focusedRowEnabled
      autoNavigateToFocusedRow
      onFocusedRowChanged={props.onFocusedRowChanged}
    >
      <RemoteOperations groupPaging={true} />
      <ColumnFixing enabled={true} />
      <SearchPanel visible width={250} />
      <HeaderFilter visible />
      <ColumnChooser enabled />
      <Sorting mode="multiple" />
      <ColumnFixing />
      <Paging defaultPageSize={40} />
      <Pager showPageSizeSelector />
      <FilterPanel visible />
      <FilterBuilderPopup />
      <Export enabled={true} />


      <Column caption="" type="buttons" width={80} allowFiltering={false} fixed={true} visibleIndex={0}>
        <Button
          name="customEdit"
          icon="edit"
          onClick={(e) => {
            history.push("/patient/new?patientId=" + e.row.data.rowKey);
          }}
        />
        <Button
          name="customEdit"
          icon="info"
          onClick={(e) => {
            props.previewPatient(e.row.data);
          }}
        />
      </Column>

      <Column dataField="firstName" fixed={true} />
      <Column dataField="lastName" fixed={true} />
      <Column dataField="patientId" />
      <Column dataField="followUp" dataType="date" format="MM/dd/yyyy" />
      <Column dataField="discipline" cellRender={disciplineCellRender}/>
      <Column dataField="directorName" />
      <Column dataField="fullAddress" />
      <Column dataField="address1" />
      <Column dataField="address2" />
      <Column dataField="isFacility" />
      <Column dataField="city" visible={false}/>
      <Column dataField="state" visible={false}/>
      <Column dataField="zip" visible={false}/>
      <Column dataField="phone" visible={false}/>
      <Column dataField="dob" dataType="date" format="MM/dd/yyyy" />
      <Column dataField="ssn" />
      <Column dataField="notes" visible={false}/>
      <Column dataField="primaryName" visible={false}/>
      <Column dataField="primaryId" visible={false}/>
      <Column dataField="secondaryName" visible={false}/>
      <Column dataField="secondaryId" visible={false}/>
      <Column dataField="doctor" visible={false}/>
      <Column dataField="doctorPhone" visible={false}/>
      <Column dataField="doctorFax" visible={false}/>
      <Column dataField="partBDeductible" visible={false}/>
      <Column dataField="partBUsedOT" caption='Part B Used OT' visible={false}/>
      <Column dataField="partBUserPTSLP" caption='Part B Used PT/SLP' visible={false}/>
      <Column dataField="referralSourceNew" caption="Referral Source" />
      <Column dataField="referralSource" caption="Referral Source Old" visible={false}/>
      <Column dataField="smsExtendedText" visible={false}/>
      <Column dataField="createdDate" sortOrder="desc" dataType="date" format="shortDateShortTime" />
      <Column dataField="addedByUserId" visible={false} />
      <Column dataField="addedByUser" />
      <Column dataField="updatedByUserId" visible={false} />
      <Column dataField="updatedByUser" />
      <Column dataField="updatedOn" dataType="date" format="shortDateShortTime" />
      <Column dataField="rowKey" visible={false} />
      <Column dataField="last4CreditCardNumber" />
      <Column dataField="lastNote"/>
      <Column dataField="lastNoteAddedOn" dataType="date" format="shortDateShortTime" />
      <Column dataField="facilityName" />

      <Toolbar>
        <Item
          location="before"
          widget="dxButton"
          options={{
            icon: "plus",
            text: onlyWidth > 1500 ? "Add Patient" : "",
            onClick: (e) => {
              history.push("/patient/new");
            },
          }}
        ></Item>
        <Item location="before" visible={onlyWidth > 1500}>
          <PatientFilter setFilter={props.setFilter} filter={props.filter} />
        </Item>
        <Item
          location="before"
          widget="dxButton"
          visible={onlyWidth <= 1500}
          options={{
            icon: "filter",
            onClick: (e) => {
              props.setFilterPopupVisible(true);
            },
          }}
        ></Item>
        <Item name="columnChooserButton" />
        <Item name="exportButton" />
        <Item name="searchPanel" />
        <Item location="after">
          <CheckBox
            text="Show Notes"
            value={props.showNotes}
            onValueChanged={(e) => props.setShowNotes(e.value)}
          />
        </Item>
      </Toolbar>
    </DataGrid>
  );
};