import React, { useState, useEffect, useRef } from "react";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    RemoteOperations,
    HeaderFilter,
    ColumnChooser,
    Sorting,
    ColumnFixing,
    FilterPanel,
    FilterBuilderPopup,
    Export,
    Editing,
    Toolbar,
    Item,
    Button
} from "devextreme-react/data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

const serviceUrl = `${apiOrigin}/api`;
export default function DirectorsDxGrid(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [remoteDataSource, setRemoteDataSource] = useState();
    const history = useHistory();
    const gridRef = useRef();

    useEffect(() => {
        const getReferralSources = async () => {
            const token = await getAccessTokenSilently();
            setRemoteDataSource(createStore({
                key: "id",
                loadUrl: serviceUrl + "/Directors",
                deleteUrl: `${serviceUrl}/Directors`,
                onBeforeSend: (e, s) => {
                    s.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                },
            }));
        };
        getReferralSources();
    }, []);

    return (
        <>{
            remoteDataSource ?
                <DataGrid
                    ref={(ref) => { gridRef.current = ref; }}
                    remoteOperations
                    dataSource={remoteDataSource}
                    height="100%"
                    width="100%"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    columnResizingMode={"widget"}
                    showColumnLines={false}
                    rowAlternationEnabled
                    focusedRowEnabled
                    autoNavigateToFocusedRow
                >
                    <Editing
                        mode="row"
                        allowAdding={false}
                        allowDeleting={true}
                        allowUpdating={false}>
                    </Editing>

                    <RemoteOperations groupPaging={true} />
                    <ColumnFixing enabled={true} />
                    <SearchPanel visible width={250} />
                    <HeaderFilter visible />
                    <ColumnChooser enabled />
                    <Sorting mode="multiple" />
                    <ColumnFixing />
                    <Paging defaultPageSize={40} />
                    <Pager showPageSizeSelector />
                    <FilterPanel visible />
                    <FilterBuilderPopup />
                    <Export enabled={true} />

                    <Column caption="" type="buttons" width={80} allowFiltering={false} fixed={true} visibleIndex={0}>
                        <Button
                            name="customEdit"
                            text="Edit"
                            onClick={(e) => {
                                history.push("/directors/edit?directorId=" + e.row.data.id);
                            }}
                        />
                        <Button name="delete" />
                    </Column>

                    <Column dataField="id" allowEditing={false} />
                    <Column dataField="firstName" />
                    <Column dataField="lastName" />
                    <Column dataField="states" visible={false} />
                    <Column dataField="zipCodes" visible={false} />

                    <Column dataField="rowKey" visible={false} />

                    <Toolbar>
                        <Item
                            location="before"
                            widget="dxButton"
                            options={{
                                icon: "plus",
                                text: "Add Director",
                                onClick: (e) => history.push('/directors/edit'),
                            }}
                        ></Item>
                        <Item name="columnChooserButton" />
                        <Item name="exportButton" />
                        <Item name="searchPanel" />
                    </Toolbar>
                </DataGrid >
                : <div></div>
        }</>
    );
};