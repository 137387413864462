import React from "react";
import { CustomInput } from 'reactstrap'

const DisciplineTypesSelect = ({ setType, selected }) => (
  <div style={{ display: "flex" }}>
    <CustomInput type="checkbox" className="disciplineTypeCheck" id="disciplineTypes" name="PT" label="PT" onChange={(e) => setType(e.target.name)} checked={selected.filter(x => x.Type === "PT").length === 1} />
    <CustomInput type="checkbox" className="disciplineTypeCheck" id="disciplineTypes2" name="OT" label="OT" onChange={(e) => setType(e.target.name)} checked={selected.filter(x => x.Type === "OT").length === 1} />
    <CustomInput type="checkbox" className="disciplineTypeCheck" id="disciplineTypes3" name="SLP" label="SLP" onChange={(e) => setType(e.target.name)} checked={selected.filter(x => x.Type === "SLP").length === 1} />
  </div>
);

export default DisciplineTypesSelect;
