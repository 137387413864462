import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { useState, useEffect, createContext, useMemo } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import styled from "styled-components";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Therapists from "./views/Therapists";
import Patients from "./views/Patients";
import PendingPatients from "./views/PendingPatients";
import StatusUpdates from "./views/StatusUpdates";
import Dashboard from "./views/Dashboard";
import history from "./history";
import PatientsList from "./views/Preview/PatientsList";
import PatientEdit from './views/Preview/PatientEdit'
import Disciplines from "./views/Disciplines";
import ReferralSourcesDxGrid from "./views/Preview/ReferralSourcesDxGrid";

// styles
import "./assets/scss/app.scss";
import LogEventsView from "./views/Preview/LogEventsView";
import { Drawer } from "@material-ui/core";
import { Box } from "@mui/system";
import { Panel, PanelBody, PanelHead } from "mui-extension";
import { Divider, Icon, IconButton, Typography } from "@mui/material";
import PatientEditPage from "./views/Preview/PatientEditPage";
import FacilitiesDxGrid from "./views/Preview/FacilitiesDxGrid";
import axios from "axios";
import UsersDxGrid from "./views/Preview/UsersDxGrid";
import AuditDxGrid from "./views/Preview/AuditDxGrid";
import DirectorsDxGrid from "./views/Preview/DirectorsDxGrid";
import DirectorEdit from "./views/Preview/DirectorEdit";
import AuditReportDxGrid from "./views/Preview/AuditReportDxGrid";
import RunPairingPage from "./views/Preview/RunPairingPage";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const RoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px;
`;

export const PatientDrawerContext = createContext({
  previewPatient: null,
  setPreviewPatient: () => { },
  reloadData: false,
  setReloadData: () => { },
  triggerReloadData: () => { }
});

export const UserProfileContext = createContext();

const App = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const [directorsAndZipcodes, setDirectorsAndZipcodes] = useState([]);
  const [therapistsIdsAndNames, setTherapistsIdsAndNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previewPatient, setPreviewPatient] = useState();
  const [reloadData, setReloadData] = useState(false);
  const [userProfile, setUserProfile] = useState();

  //console.log("env: " + process.env.NODE_ENV);

  const apiOrigin = process.env.REACT_APP_AZURE_FUNCTION_URL;

  useEffect(() => {
    const getPatients = async () => {
      const token = await getAccessTokenSilently();

      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        let user = await axios.get(`${apiOrigin}/api/UserProfile`, config);
        setUserProfile(user.data);

        const response = await fetch(
          `${apiOrigin}/api/fields/therapists?fields=TherapistId,FirstName,LastName,IsDirector,Discipline,rowkey`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        const aresponse = await fetch(`${apiOrigin}/api/directorsAndZipCodes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const aresponseData = await aresponse.json();
        //console.log(aresponseData);
        setDirectorsAndZipcodes([...aresponseData]);
        setTherapistsIdsAndNames([...responseData]);
        setLoading(false);
      } catch (error) {
        alert("error: " + error);
      }
    };

    getPatients();
  }, []);

  function triggerReloadData() {
    setReloadData(!reloadData);
  }

  const contextValue = useMemo(
    () => ({ previewPatient, setPreviewPatient, reloadData, setReloadData, triggerReloadData }),
    [previewPatient, reloadData]
  );

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading || loading) {
    return <Loading />;
  }

  return (
    <PatientDrawerContext.Provider value={contextValue}>
      <UserProfileContext.Provider value={userProfile}>
        <>
          <Router history={history}>
            <AppWrapper>
              <NavBar />

              <RoutesContainer>
                <Switch>
                  <Route path="/" exact component={Dashboard} />
                  <Route
                    path="/therapists"
                    render={(props) => (
                      <Therapists
                        {...props}
                        directorsAndZipcodes={directorsAndZipcodes}
                      />
                    )}
                  />
                  <Route
                    path="/patients_old"
                    render={(props) => (
                      <Patients
                        {...props}
                        directorsAndZipcodes={directorsAndZipcodes}
                        therapists={therapistsIdsAndNames}
                      />
                    )}
                  />
                  <Route
                    path="/PendingPatients_old"
                    render={(props) => (
                      <PendingPatients
                        {...props}
                        directorsAndZipcodes={directorsAndZipcodes}
                        therapists={therapistsIdsAndNames}
                      />
                    )}
                  />
                  <Route path="/statusupdates" component={StatusUpdates} />

                  <Route
                    path="/patientsList"
                    render={(props) => (
                      <PatientsList
                        {...props}
                        therapistsData={therapistsIdsAndNames}
                        directorsAndZipcodes={directorsAndZipcodes}
                      />
                    )}
                  />

                  <Route
                    path="/patient/new"
                    render={(props) => (
                      <PatientEditPage
                        {...props}
                        therapistsData={therapistsIdsAndNames}
                        directorsAndZipcodes={directorsAndZipcodes}
                      />
                    )}
                  />
                  <Route
                    path="/disciplines"
                    render={(props) => (
                      <Disciplines
                        {...props}
                        therapistsData={therapistsIdsAndNames}
                      />
                    )}
                  />

                  <Route path="/logs" render={(props) => (<LogEventsView {...props} />)} />
                  <Route path="/directors" exact render={(props) => (<DirectorsDxGrid {...props} />)} />
                  <Route path="/directors/edit" render={(props) => (<DirectorEdit {...props} />)} />
                  <Route path="/ReferralSources" render={() => <ReferralSourcesDxGrid />} />
                  <Route path="/Facilities" render={() => <FacilitiesDxGrid />} />
                  <Route path="/Users" render={() => <UsersDxGrid />} />
                  <Route path='/Audit' render={() => <AuditDxGrid />} />
                  <Route path='/AuditReport' render={() => <AuditReportDxGrid />} />
                  <Route path="/runPairing" render={() => <RunPairingPage />} />
                </Switch>
              </RoutesContainer>
            </AppWrapper>
          </Router>

          <Drawer anchor="right" open={previewPatient} variant="temporary" onClose={() => setPreviewPatient(null)}>
            <Box width='85vw'>
              {previewPatient &&
                <Panel>
                  <PanelHead icon="person" action={
                    <IconButton onClick={() => setPreviewPatient(null)}>
                      <Icon>close</Icon>
                    </IconButton>
                  }>
                    <Box display='flex' alignContent='center'>
                      <Typography variant="h5" component="span">
                        {previewPatient?.rowKey ? (previewPatient.firstName + " " + previewPatient.lastName) : "New Patient"}
                      </Typography>
                    </Box>
                  </PanelHead>
                  <Divider />
                  <PanelBody>
                    <PatientEdit patientId={previewPatient.rowKey}
                      directorsAndZipcodes={directorsAndZipcodes}
                      onClose={() => setPreviewPatient(null)}
                      reloadData={triggerReloadData}
                      showSaveAndClose
                    />
                  </PanelBody>

                  {/* <PanelFoot>
                <Button size="small" color="primary" variant="contained">
                  Confirm
                </Button>
                <Button size="small" color="primary" variant="outlined">
                  Cancel
                </Button>

              </PanelFoot> */}
                </Panel>
              }
            </Box>
          </Drawer>
        </>
      </UserProfileContext.Provider>
    </PatientDrawerContext.Provider>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
